<div [ngSwitch]="attributes.type">

    <!--none-->
    <div *ngSwitchCase="eFieldType.None">
        &nbsp;
    </div>

    <!--title-->
    <div *ngSwitchCase="eFieldType.Title">
        <h4 class="mb-1 mt-1">
            <i *ngIf="attributes.icon!=undefined && attributes.icon!=null && attributes.icon!=''" [data-feather]="attributes.icon" [size]="(attributes.fontSize!=null && attributes.fontSize!=undefined && attributes.fontSize!='') ? attributes.fontSize : attributes.size"
                [ngClass]="attributes.classiCss"></i> &nbsp;
            <span class="align-middle" style="font-size: {{ attributes.fontSize }}px; color: {{ attributes.fontColor }};">{{attributes.label}}</span>
        </h4>
    </div>

    <!--button-->
    <div *ngSwitchCase="eFieldType.Button">
        <button type="button" (click)="fieldButtonClick()" class="btn {{attributes.btncolor}} btn-block" rippleEffect style="margin-top: 22px;">
      <span *ngIf="attributes.icon!=undefined && attributes.icon!=null && attributes.icon!=''"  [data-feather]="attributes.icon" [class]="'mr-25'"></span>{{attributes.label}}
    </button>
    </div>

    <!--text field-->
    <div *ngSwitchCase="eFieldType.Text" [formGroup]="parentFormGroup">
        <label [attr.for]="attributes.name">{{attributes.label}}
      <span *ngIf="isRequired" style="color: red;">&nbsp;*</span>
    </label>
        <input *ngIf="attributes.numberMask === 2" [ngClass]="attributes.classiCss" [formControlName]="attributes.name" [readonly]="attributes.readonly" type="text" class="form-control" placeholder="10.000,00" mask="separator.2" thousandSeparator="." suffix=" €">
        <input *ngIf="attributes.numberMask === 1" [ngClass]="attributes.classiCss" [formControlName]="attributes.name" [readonly]="attributes.readonly" type="text" class="form-control" placeholder="10.000" mask="separator" thousandSeparator=".">
        <input *ngIf="attributes.numberMask === 0" [ngClass]="attributes.classiCss" [formControlName]="attributes.name" [readonly]="attributes.readonly" type="text" class="form-control">
        <div *ngIf="attributes.hasSaved" class="validation-errors">
            <ng-container *ngFor="let validation of attributes.validations">
                <div class="errorMessage text-danger" *ngIf="errorControl.hasError(validation.type)">
                    {{ validation.message }}
                </div>
            </ng-container>
        </div>
    </div>

    <!--date picker classico-->
    <div *ngSwitchCase="eFieldType.Date" [formGroup]="parentFormGroup">
        <label [attr.for]="attributes.name">{{attributes.label}}
      <span *ngIf="isRequired" style="color: red;">&nbsp;*</span>
    </label>
        <ng2-flatpickr #flatpickerbasic [ngClass]="attributes.classiCss" [formControlName]="attributes.name" [readonly]="attributes.readonly" [config]="attributes.dateOptions" [setDate]="attributes.value" [id]="attributes.name"></ng2-flatpickr>
        <div *ngIf="attributes.hasSaved" class="validation-errors">
            <ng-container *ngFor="let validation of attributes.validations">
                <div class="errorMessage text-danger" *ngIf="errorControl.hasError(validation.type)">
                    {{ validation.message }}
                </div>
            </ng-container>
        </div>
    </div>

    <!--select-->
    <div *ngSwitchCase="eFieldType.ComboText" [formGroup]="parentFormGroup">
        <label [attr.for]="attributes.name">{{attributes.label}}
      <span *ngIf="isRequired" style="color: red;">&nbsp;*</span>
    </label>
        <ng-select [items]="selectBasic" [multiple]="attributes.multiple" bindLabel="c2" bindValue="key" [loading]="selectBasicLoading" [formControlName]="attributes.name" [clearable]="!attributes.readonly" [searchable]="!attributes.readonly" [readonly]="attributes.readonly"
            (change)="onComboChange($event)"> </ng-select>
        <div *ngIf="attributes.hasSaved" class="validation-errors">
            <ng-container *ngFor="let validation of attributes.validations">
                <div class="errorMessage text-danger" *ngIf="errorControl.hasError(validation.type)">
                    {{ validation.message }}
                </div>
            </ng-container>
        </div>
    </div>
    <!--check field-->
    <div *ngSwitchCase="eFieldType.CheckBox" [formGroup]="parentFormGroup">
        <div class="form-check form-check-inline" style="margin-top: 35px;">
            <input class="form-check-input" (change)="onCheckBoxChange($event)" type="checkbox" [id]="attributes.name" [checked]="(attributes.value === '1')" />
            <label class="form-check-label" [for]="attributes.name">{{attributes.label}}</label>
        </div>
    </div>

</div>