import { Injectable } from '@angular/core';
import { PostsConfiguratorComponent } from './posts-configurator.component';
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { User } from 'app/auth/models';
import { RestService, restApiUrl, restApiKey } from '../services/rest.service';

@Injectable({
  providedIn: 'root'
})
export class PostsConfiguratorService {

  component: PostsConfiguratorComponent = null;
  currentUser: User;

  constructor(
    private rest: RestService,
    private http: HttpClient,
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    setTimeout(() => {
      this.loadApartments();
    }, 10);

  }


  callRestAPI_(request:any): Observable<any> {
    return this.http.post<any>(restApiUrl, request, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
      })
    });
  }

  callRestAPI(request:any, successCallback, errorCallBack = undefined, context = null) {
    this.callRestAPI_(request).subscribe(
      response => {
        // aggiorna il token
        this.currentUser.token = response.token;
        localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
        if (response.success) {
          successCallback(response, context);
        } else {
          if (errorCallBack !== undefined) {
            errorCallBack(response.error);
          } else {
            this.component.showAlert('danger', 'ERRORE', response.error);
          }
        }
      },
      error => {
        if (errorCallBack !== undefined) {
          errorCallBack(error);
        } else {
          this.component.showAlert('danger', 'ERRORE', error);
        }
      }
    );
  }

  /*
  DOCUMENTAZIONE:
  
  OGGETTO: FORM
    Action: onPreSave, viene chiamata una funzione qui con il parametro parms che contiene:
      { record, jump } dove record contiene i campi validati del form e jump che deve essere passato senza modifiche.
      La funzione deve restituire sempre il parametro parms con parms.success = true se si vuole
      procedere al salvataggio oppure parms.success = false se non si vuole salvare.
      Eventuali messaggi di errore devono essere gestiti qui.

  */

  //===> BEGIN - Inizio funzioni dello sviluppatore

  postGoTo(data: any) {
    localStorage.setItem('fkparent', data['key']);
    this.component.refresh_tblListOfPosts();
  }

  getFkparent() {
    var fkparent = localStorage.getItem('fkparent');
    if (fkparent === null) {
      fkparent = '0';
    }
    if (fkparent === undefined) {
      fkparent = '0';
    }
    return fkparent;
  }

  setFkParent() {
    this.component.parmsListOfPosts.restApi.apiParams.fkparent = this.getFkparent();
  }

  getAppartamenti(value:string) {
    var text = '';
    if (value !== '') {
      const arr = value.split(',');
      arr.forEach(item => {
        this.component.globals.apartments.forEach(room => {
          if (room.key === item) {
            text += (text === '' ? '' : ',') + room.c2;
          }
        });
      });
    }
    return text;
  }

  // carica l'elenco degli appartamenti
  loadApartments() {
    this.callRestAPI({
        tabletype: 2,
        apikey: restApiKey,
        method: 'ListOfRooms',
        sorter: {},
        prefilters: {},
        page: 0,
        filters: {},
        rowsPerPage: -1,
        token: this.currentUser.token,
        apiParams: {}
    }, this.loadApartmentsSuccess, undefined, this);
  }

  loadApartmentsSuccess(response, context) {
    context.component.globals.apartments = response.querydata.data;
    context.component.refresh_tblListOfPosts();
  }

  duplicatePost(data: any) {
    const fkpost = parseInt(data['key']);
    this.callRestAPI({
      apikey: restApiKey,
      method: 'sharelink',
      action: 'duplicatepost',
      fk: fkpost,
      token: this.currentUser.token,
    }, this.refreshPosts, undefined, this);
  }

  duplicatePosts(data: any) {
    const fkpost = parseInt(data['key']);
    this.callRestAPI({
      apikey: restApiKey,
      method: 'sharelink',
      action: 'duplicateposts',
      fk: fkpost,
      token: this.currentUser.token,
    }, this.refreshPosts, undefined, this);
  }

  refreshPosts(response, context) {
    context.component.refresh_tblListOfPosts();
  }

  postUp(data: any) {
    const fkpost = parseInt(data['key']);
    this.callRestAPI({
      apikey: restApiKey,
      method: 'sharelink',
      action: 'setpost:up',
      fk: fkpost,
      token: this.currentUser.token,
    }, this.refreshPosts, undefined, this);
  }

  postDown(data: any) {
    const fkpost = parseInt(data['key']);
    this.callRestAPI({
      apikey: restApiKey,
      method: 'sharelink',
      action: 'setpost:down',
      fk: fkpost,
      token: this.currentUser.token,
    }, this.refreshPosts, undefined, this);
  }

  btnGoToHome() {
    localStorage.setItem('fkparent', '0');
    this.component.refresh_tblListOfPosts();
  }

  //===> END - Fine funzioni dello sviluppatore

}

