import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';

import { RestService } from '../../../app/main/services/rest.service';
import { AutoLogOffServiceService } from '../../main/services/auto-log-off-service.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(
    private _http: HttpClient,
    private _toastrService: ToastrService,
    private _restservice: RestService,
    private _autologoff: AutoLogOffServiceService,
    ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(email: string, password: string) {

    return this._restservice.callLogin(email, password)
    .pipe(
      map(response => {

        if (response.success) {
          var menuprofile = response.menuprofile.split('|');
          var currentUser:User = {
            id: response.userdata.pk,
            email: response.userdata.email,
            password: '',
            firstName: response.userdata.name,
            lastName: response.userdata.surname,
            avatar: 'avatar-s-11.jpg',
            token: response.token,
            //role: (email === 'admin' ? Role.Admin : Role.User),
            role: menuprofile,
          };
          localStorage.setItem('currentUser', JSON.stringify(currentUser));
          // notify
          this.currentUserSubject.next(currentUser);
        }
        return response;

      })
    );

    /*return this._http
      .post<any>(`${environment.apiUrl}/users/authenticate`, { email, password })
      .pipe(
        map(user => {
          // login successful if there's a jwt token in the response
          if (user && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user));

            // Display welcome toast!
            setTimeout(() => {
              this._toastrService.success(
                'You have successfully logged in as an ' +
                  user.role +
                  ' user to Vuexy. Now you can start to explore. Enjoy! 🎉',
                '👋 Welcome, ' + user.firstName + '!',
                { toastClass: 'toast ngx-toastr', closeButton: true }
              );
            }, 2500);

            // notify
            this.currentUserSubject.next(user);
          }

          return user;
        })
      );*/
  }

  /**
   * User logout
   *
   */
  logout() {
    console.log('AuthenticationService.logout: user logged out...');
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    // notify
    this.currentUserSubject.next(null);
    this._autologoff.onLogout();
  }
}
