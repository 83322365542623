<div class="content-wrapper container-xxl p-0">
  <div class="content-body">

    <!-- <app-content-header [contentHeader]="contentHeader"></app-content-header> -->

    <!-- Modal ALERT -->
    <ng-template #modalDanger let-modal>
      <div class="modal-header">
        <h5 class="modal-title" id="myModalLabel160">{{ alertTitle }}</h5>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="OK">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" tabindex="0" ngbAutofocus>
        {{ alertMessage }}

        <div *ngIf="spinner" class="d-flex justify-content-center my-1">
          <div class="spinner-border text-success" role="status"></div>
        </div>

      </div>
      <div *ngIf="!spinner" class="modal-footer">
        <button type="button" class="btn btn-{{ alertClass }}" (click)="modal.close('Accept click')" rippleEffect>
          OK
        </button>
      </div>
    </ng-template>
    <!-- / Modal -->


    <!-- Modal SELEZIONA PRODOTTO -->
    <ng-template #modalSelectProduct let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel4">Seleziona un prodotto dall'elenco...</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" tabindex="0" ngbAutofocus>

        <div class="table-responsive">
          <table class="table table-sm">
            <thead>
              <tr>
                <th>Descrizione</th>
                <th>RestAPI Url</th>
              </tr>
            </thead>
            <tbody>                        
              <tr *ngFor="let product of products" (click)="onSelectProductRow(product)">
                <td>{{ product.descrizione }}</td>
                <td>{{ product.restapiurl }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div *ngIf="spinner" class="d-flex justify-content-center my-1">
          <div class="spinner-border text-success" role="status"></div>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.close('Accept click');" rippleEffect>
          OK
        </button>
      </div>
    </ng-template>
    <!-- / Modal -->


    <!-- Modal Username/Password Prodotto -->
    <ng-template #modalLoginProdotto let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel4">Accesso al prodotto...</h4>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" tabindex="0" ngbAutofocus>

        <div class="form-group">
          <label for="basicInput">Username</label>
          <input
            type="text"
            class="form-control  form-control-sm"
            id="basicInput"
            placeholder="Inserire la username..."
            [(ngModel)]="product.adminuser"
          />
        </div>

        <div class="form-group">
          <label for="basicInput">Password</label>
          <input
            type="password"
            class="form-control  form-control-sm"
            id="basicInput"
            placeholder="Inserire la username..."
            [(ngModel)]="product.adminpassword"
          />
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.close('Accept click'); btnLoginProdotto()" rippleEffect>
          OK
        </button>
      </div>
    </ng-template>
    <!-- / Modal -->



    <!-- Basic Alerts start -->
    <section id="reporteditor-page">
      <div class="row">

        <div class="col-4">
          <div class="card">
            <div class="card-header" style="padding-bottom: 0px;">
              <h4 class="card-title">
                Prodotto:&nbsp;<b>{{ product.descrizione }}</b>
              </h4>
              <button (click)="btnChooseProduct()" type="button" class="btn btn-icon rounded-circle" rippleEffect>
                <span [data-feather]="'edit'"></span>
              </button>
            </div>
            <div class="card-header" style="padding-top: 0px;">
              <h4 class="card-title">
                Report tipo:&nbsp;
                <app-report-type [rpt]="report"></app-report-type>
              </h4>

              <div ngbDropdown>
                <a
                  ngbDropdownToggle
                  href="javascript:void(0);"
                  class="hide-arrow"
                  id="dropdownBrowserState"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i data-feather="more-vertical" [size]="18" class="text-body cursor-pointer"></i>
                </a>
                <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownBrowserState">
                  <a (click)="btnOpenNuovo()" ngbDropdownItem>Nuovo</a>
                  <a (click)="btnCarica(modalCarica)" ngbDropdownItem>Carica</a>
                  <a (click)="btnSalva()" ngbDropdownItem>Salva</a>
                  <a (click)="btnEsegui()" ngbDropdownItem>Esegui</a>
                  <a (click)="btnElimina()" ngbDropdownItem>Elimina</a>
                </div>
              </div>

            </div>
            <div class="card-body pb-1">

              <!-- Modal NUOVO -->
              <ng-template #modalNuovo let-modal>
                <div class="modal-header">
                  <h4 class="modal-title" id="myModalLabel4">Nuovo report...</h4>
                  <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body" tabindex="0" ngbAutofocus>

                  <select
                    class="form-control"
                    id="basicSelect"
                    [(ngModel)]="reporttype"
                  >
                    <option value="1">RestAPI</option>
                    <option value="2">Section</option>
                    <option value="3">Widget Icon</option>
                    <option value="4">Widget Bar</option>
                    <option value="5">Widget Area</option>
                    <option value="6">Widget Line</option>
                    <option value="7">Report</option>
                    <option value="8">Filtro Data</option>
                  </select>
    
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary" (click)="modal.close('Accept click'); btnNuovo()" rippleEffect>
                    OK
                  </button>
                </div>
              </ng-template>
              <!-- / Modal -->

              <!-- Modal CARICA -->
              <ng-template #modalCarica let-modal>
                <div class="modal-header">
                  <h4 class="modal-title" id="myModalLabel4">Scegli il report dall'elenco...</h4>
                  <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body" tabindex="0" ngbAutofocus>

                  <div class="table-responsive">
                    <table class="table table-sm">
                      <thead>
                        <tr>
                          <th>Tipo</th>
                          <th>Codice</th>
                          <th>Area</th>
                          <th>SP</th>
                          <th>Stato</th>
                          <th>Titolo</th>
                        </tr>
                      </thead>
                      <tbody>                        
                        <tr *ngFor="let rpt of reports" (click)="onCaricaRow(rpt)">
                          <td>
                            <app-report-type [rpt]="rpt"></app-report-type>
                          </td>
                          <td><b>{{ rpt.code }}</b></td>
                          <td>{{ rpt.area }}</td>
                          <td>{{ rpt.sp }}</td>
                          <td>
                            <span *ngIf="rpt.enabled" class="badge badge-pill badge-light-success mr-1">Attivo</span>
                            <span *ngIf="!rpt.enabled" class="badge badge-pill badge-light-warning mr-1">Disattivato</span>
                          </td>
                          <td>{{ rpt.title }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div *ngIf="spinner" class="d-flex justify-content-center my-1">
                    <div class="spinner-border text-success" role="status"></div>
                  </div>

                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary" (click)="modal.close('Accept click'); btnCaricaClose()" rippleEffect>
                    OK
                  </button>
                </div>
              </ng-template>
              <!-- / Modal -->

              <div class="row">
                <div class="col-5">
                  <div class="form-group">
                    <label for="basicInput">Codice</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      id="basicInput"
                      placeholder="Codice del report"
                      [(ngModel)]="report.code"
                    />
                  </div>
                </div>
                <div class="col-5">
                  <div class="form-group">
                    <label for="basicInput">Area</label>
                    <input
                      type="text"
                      class="form-control  form-control-sm"
                      id="basicInput"
                      placeholder="Area del report"
                      [(ngModel)]="report.area"
                    />
                  </div>
                </div>
                <div class="col-2">
                  <div class="form-group">
                    <label for="basicInput">SP</label>
                    <input
                      type="text"
                      class="form-control  form-control-sm"
                      id="basicInput"
                      placeholder="Area del report"
                      [(ngModel)]="report.sp"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="basicInput">Titolo</label>
                <input
                  type="text"
                  class="form-control  form-control-sm"
                  id="basicInput"
                  placeholder="Titolo del report"
                  [(ngModel)]="report.title"
                />
              </div>

              <div *ngIf="report.type === 3" class="form-group">
                <label for="basicInput">Icona</label>
                <input
                  type="text"
                  class="form-control  form-control-sm"
                  id="basicInput"
                  placeholder="Specifica una icona"
                  [(ngModel)]="report.icon"
                />
              </div>

              <div *ngIf="(report.type === 3) || (report.type === 4) || (report.type === 5) || (report.type === 6)" class="form-group">
                <label for="basicInput">Colore icona</label>
                <select
                  class="form-control  form-control-sm"
                  id="basicSelect"
                  (change)="onChangeIconColor()"
                  [(ngModel)]="reporticoncolor"
                >
                  <option value="0">Pink</option>
                  <option value="1">Purple</option>
                  <option value="2">LightBlue</option>
                  <option value="3">Teal</option>
                  <option value="4">Orange</option>
                  <option value="5">LightGreen</option>
                  <option value="6">Indigo</option>
                </select>
              </div>

              <div *ngIf="(report.type === 3) || (report.type === 7)" class="form-group">
                <label for="basicInput">Azione su click</label>
                <select
                  class="form-control  form-control-sm"
                  id="basicSelect"
                  [(ngModel)]="report.routePath"
                  (change)="onChangeRoutePath()"
                >
                  <option value="">Nessuna</option>
                  <option value="RUN_REPORT">Esegui report</option>
                  <option value="SHOW_AREA">Visualizza Area</option>
                </select>
              </div>

              <div *ngIf="(report.type === 3) || (report.type === 7)" class="form-group">
                <label for="basicInput">{{ routePathDescription }}</label>
                <input
                  type="text"
                  class="form-control  form-control-sm"
                  id="basicInput"
                  [placeholder]="routePathDescription"
                  [(ngModel)]="report.routePathParms.code"
                />
              </div>

              <div *ngIf="(report.type === 3) || (report.type === 7)" class="form-group">
                <label for="basicInput">{{ routePathDescription2 }}</label>
                <input
                  type="text"
                  class="form-control  form-control-sm"
                  id="basicInput"
                  [placeholder]="routePathDescription2"
                  [(ngModel)]="report.routePathParms.description"
                />
              </div>
        
            </div>
          </div>
        </div>

        <div class="col-8">
          <div *ngIf="(report.type !== 2) && (report.type !== 8)" class="card">
            <div class="card-header">
              <h4 class="card-title">Report Editor</h4>
            </div>
            <div class="card-body pb-1">
              <label for="basicTextarea">Query sql</label>
              <fieldset class="form-group">
                <textarea
                  class="form-control"
                  id="basicTextarea"
                  rows="3"
                  placeholder="Query sql..."
                  [(ngModel)]="report.source.query"
                ></textarea>
              </fieldset>
            </div>
          </div>
        </div>

      </div>


              
      <div *ngIf="(report.type === 1) || (report.type === 7)" class="row">
        <div class="col-12">

          <!-- Modal EDIT COLUMN -->
          <ng-template #modalEditColumn let-modal>
            <div class="modal-header">
              <h4 class="modal-title" id="myModalLabel4">Definizione colonna report</h4>
              <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" tabindex="0" ngbAutofocus>

              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="inlineCheckbox1"
                  [(ngModel)]="column.visible"
                />
                <label class="form-check-label" for="inlineCheckbox1">Visibile</label>
              </div>

              <div class="form-group">
                <label for="basicInput">Etichetta</label>
                <input
                  type="text"
                  class="form-control  form-control-sm"
                  id="basicInput"
                  placeholder="Etichetta della colonna..."
                  [(ngModel)]="column.label"
                />
              </div>

              <div class="form-group">
                <label for="basicInput">Formato</label>
                <select
                class="form-control"
                id="basicSelect"
                [(ngModel)]="formattype"
                >
                  <option value="0">Generico</option>
                  <option value="1">dd/mm/yyyy</option>
                  <option value="2">999.99 €</option>
                  <option value="3">Testo</option>
                  <option value="4">Testo 10 ch</option>
                  <option value="5">Testo 20 ch</option>
                  <option value="6">dd mmm</option>
                  <option value="7">dd/mm/yy</option>
                  <option value="8">dd/mm/yyyy</option>
                  <option value="9">dd-MMM-yy</option>
                  <option value="10">dd mmm yyyy</option>
                  <option value="11">dd-mm-yyyy hh:mm</option>
                  <option value="12">hh:mm</option>
                  <option value="13">mmm yyyy</option>
                  <option value="14">999</option>
                  <option value="15">999.99</option>
                  <option value="16">999.99999</option>
                  <option value="17">99.9 %</option>
                  <option value="18">999.99 €</option>
                  <option value="19">mmm yyyy</option>
                  <option value="100">Speciale</option>
                </select>
              </div>

              <div class="form-group">
                <label for="basicInput">Tipo Speciale</label>
                <select
                class="form-control"
                id="basicSelect"
                [(ngModel)]="formatsubtype"
                >
                  <option value="0">Nessuno</option>
                  <option value="1">Badge Range</option>
                  <option value="2">Icon</option>
                  <option value="3">Icon Range</option>
                  <option value="4">Avatar 2 linee</option>
                  <option value="5">Custom</option>
                  <option value="6">Immagine</option>
                </select>
              </div>

              <div class="form-group">
                <label for="basicInput">Tipo Filtro</label>
                <select
                class="form-control"
                id="basicSelect"
                [(ngModel)]="filtertype"
                >
                  <option value="0">Nessuno</option>
                  <option value="1">Testo</option>
                  <option value="2">Combo (opzioni in specifica)</option>
                  <option value="3">Date</option>
                  <option value="4">Combo (query in specifica)</option>
                </select>
              </div>

              <div class="form-group">
                <label for="basicInput">Campo Filtro</label>
                <input
                  type="text"
                  class="form-control  form-control-sm"
                  id="basicInput"
                  placeholder="Nome del campo filtro..."
                  [(ngModel)]="column.filterBy"
                />
              </div>

              <div class="form-group">
                <label for="basicInput">Specifica Filtro</label>
                <textarea
                  rows="3"
                  type="text"
                  class="form-control  form-control-sm"
                  id="basicInput"
                  placeholder="Specifica per il filtro..."
                  [(ngModel)]="column.filterSpec"
                ></textarea>
              </div>

              <div class="form-group">
                <label for="basicInput">Larghezza colonna</label>
                <input
                  type="text"
                  class="form-control  form-control-sm"
                  id="basicInput"
                  placeholder="Nome del campo ordinamento..."
                  [(ngModel)]="widthperc"
                />
              </div>

              <div class="form-group">
                <label for="basicInput">Campo Ordinamento</label>
                <input
                  type="text"
                  class="form-control  form-control-sm"
                  id="basicInput"
                  placeholder="Nome del campo ordinamento..."
                  [(ngModel)]="column.sortBy"
                />
              </div>

              <div class="form-group">
                <label for="basicInput">Allineamento</label>
                <select
                class="form-control"
                id="basicSelect"
                [(ngModel)]="column.alignment"
                >
                  <option value="left">Sinistra</option>
                  <option value="center">Centrato</option>
                  <option value="right">Destra</option>
                </select>
              </div>

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" (click)="modal.close('Accept click'); btnSaveColumn();" rippleEffect>
                OK
              </button>
            </div>
          </ng-template>
          <!-- / Modal -->

          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Elenco Colonne</h4>

              <div ngbDropdown>
                <a
                  ngbDropdownToggle
                  href="javascript:void(0);"
                  class="hide-arrow"
                  id="dropdownBrowserState"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i data-feather="more-vertical" [size]="18" class="text-body cursor-pointer"></i>
                </a>
                <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownBrowserState">
                  <a (click)="btnNewColumn()" ngbDropdownItem>Nuova</a>
                </div>
              </div>
  
            </div>
            <div class="card-body pb-1">

              <div class="table-responsive">
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th>Visibile</th>
                      <th>Etichetta</th>
                      <th>Formato</th>
                      <th>Tipo filtro</th>
                      <th>Campo filtro</th>
                      <th>Specifica filtro</th>
                      <th>Campo ord.</th>
                      <th>All.</th>
                      <th>Azioni</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let col of report.columns">
                      <td>
                        <i [class]="(col.visible ? 'feather icon-eye font-medium-2 text-success' : 'feather icon-eye-off font-medium-2 text-secondary')"></i>
                      </td>
                      <td>{{ col.label }}</td>
                      <td>
                        <app-report-field-format [col]="col"></app-report-field-format>
                      </td>
                      <td>
                        <span *ngIf="col.filterType === 0" class="badge badge-pill badge-light-secondary mr-1">
                          <i data-feather="more-horizontal"></i>&nbsp;n.d.
                        </span>
                        <span *ngIf="col.filterType === 1" class="badge badge-pill badge-light-primary mr-1">
                          <i data-feather="type"></i>&nbsp;testo
                        </span>
                        <span *ngIf="col.filterType === 2" class="badge badge-pill badge-light-warning mr-1">
                          <i data-feather="list"></i>&nbsp;combo
                        </span>
                        <span *ngIf="col.filterType === 3" class="badge badge-pill badge-light-success mr-1">
                          <i data-feather="calendar"></i>&nbsp;data
                        </span>
                        <span *ngIf="col.filterType === 4" class="badge badge-pill badge-light-warning mr-1">
                          <i data-feather="database"></i>&nbsp;combo sql
                        </span>
                      </td>
                      <td>{{ col.filterBy }}</td>
                      <td>{{ col.filterSpec }}</td>
                      <td>{{ col.sortBy }}</td>
                      <td>
                        <span *ngIf="col.alignment === 'left'" class="badge badge-pill badge-light-success mr-1">
                          <i data-feather="align-left"></i>
                        </span>
                        <span *ngIf="col.alignment === 'center'" class="badge badge-pill badge-light-warning mr-1">
                          <i data-feather="align-center"></i>
                        </span>
                        <span *ngIf="col.alignment === 'right'" class="badge badge-pill badge-light-info mr-1">
                          <i data-feather="align-right"></i>
                        </span>
                      </td>
                      <td>
                        <span (click)="onColumnRow(col)"><i data-feather="edit"></i></span>
                        &nbsp;<span (click)="btnRowClickUp(col)"><i data-feather="arrow-up"></i></span>
                        &nbsp;<span (click)="btnRowClickDown(col)"><i data-feather="arrow-down"></i></span>
                        &nbsp;<span (click)="btnRowClickDelete(col)"><i data-feather="trash-2"></i></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </div>

        </div>
        <!-- <div class="col-6">

          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Elenco Pre-Filtri</h4>
            </div>
            <div class="card-body pb-1">
              <p class="card-text">Qui ci va l'elenco dei pre-filtri.</p>
            </div>
          </div>

          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Elenco Filtri</h4>
            </div>
            <div class="card-body pb-1">
              <p class="card-text">Qui ci va l'elenco dei filtri.</p>
            </div>
          </div>

        </div> -->
      </div>

      <div class="row">
        <div class="col-12">

          <div *ngIf="querydata.columns.length > 0" class="card">
            <div class="card-header">
              <h4 class="card-title">Risultato</h4>
            </div>
            <div class="card-body pb-1">

              <div class="table-responsive">
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th *ngFor="let column of querydata.columns">
                        {{ column.title }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of querydata.data">
                      <td *ngFor="let column of querydata.columns">
                        {{ data[column.key] }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
    
            </div>
          </div>

        </div>
      </div>




    </section>
    <!-- Basic Alerts end -->
  </div>
</div>
