<div class="tab-pane" id="formFields">
    <form class="form-validate" [formGroup]="myForm" (ngSubmit)="submit()" method="post" novalidate>
        <div class="row">
            <!--COMPONENTE CAMPI CHE FANNO PARTE DEL FORM-->
            <div *ngFor="let field of fields" class="col-md-{{field.colSpace}}">
                <app-field [form]="myForm" [field]="field"></app-field>
            </div>
        </div>
        <div class="row mt-1">
            <div class="col-12 d-flex flex-sm-row flex-column mt-2">
                <!--BOTTONI PER FUNZIONI CUSTOM-->
                <button type="button" class="{{btn.classi}}" *ngFor="let btn of extraButtonActions" (click)="this.formsService[btn.funct](btn.params)" rippleEffect>{{btn.text}}</button>
                <!--BOTTONE SALVA-->
                <button type="submit" [disabled]="!myForm.valid" class="btn btn-primary mb-1 mb-sm-0 mr-0 mr-sm-1" rippleEffect>Salva </button>
                <!--BOTTONE BACK-->
                <button type="reset" class="btn btn-outline-secondary" rippleEffect (click)="goBack()">Indietro</button>
            </div>
        </div>
    </form>
</div>