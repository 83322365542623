import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { CoreCommonModule } from '@core/common.module';

import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';

import { AuthGuard } from 'app/auth/helpers';

import { TreeModule } from '@circlon/angular-tree-component';

// componente relativo a questo modulo
import { SysUsersListComponent } from './sys-users-list.component';

// componenti shared
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { AgeTableModule } from '../components/age-component/age-table/age-table.module';

// componenti esterni


// routing
const routes: Routes = [
  {
    path: 'sysuserslist',
    component: SysUsersListComponent,
    data: { animation: 'sysuserslist' },
    canActivate: [AuthGuard]
  },
  {
    path: 'sysuserslist/:pk',
    component: SysUsersListComponent,
    data: { animation: 'sysuserslist' },
    canActivate: [AuthGuard]
  },
];

@NgModule({
  declarations: [SysUsersListComponent],
  imports: [
    RouterModule.forChild(routes),
    NgbModule,
    ContentHeaderModule,
    CardSnippetModule,
    Ng2FlatpickrModule,
    CoreCommonModule,
    CommonModule,
    FormsModule,
    SharedComponentsModule,
    AgeTableModule,
    TreeModule,
  ],
})
export class SysUsersListModule { }

