<div *ngIf="column.formatType !== FORMAT_ACTION">
  {{(row[column.key].length>30)? (row[column.key] | slice:0:30)+'..':(row[column.key]) }}
</div>
<div *ngIf="column.formatType === FORMAT_ACTION">
  <div *ngIf="(column.subType === 0) || (column.subType === undefined)">
    {{(row[column.key].length>30)? (row[column.key] | slice:0:30)+'..':(row[column.key]) }}
  </div>
  <div *ngIf="column.subType === 1">
    <span class="badge badge-pill mr-1" [ngClass]="itemclass">{{ itemlabel }}</span>
  </div>
  <div *ngIf="column.subType === 2">
    <i size="18" [data-feather]="itemicon"></i>
  </div>
  <div *ngIf="column.subType === 3">
    <div class="avatar avatar-status" [ngClass]="itemclass">
      <i class="feather avatar-icon font-medium-1" [ngClass]="itemicon"></i>
    </div>
  </div>
  <div *ngIf="column.subType === 4">
    <div class="d-flex align-items-center">
      <div *ngIf="itemavatar !== ''" class="avatar mr-1 ml-0" [ngClass]="itemclass">
          <div class="avatar-content">{{ itemavatar }}</div>
      </div>
      <div>
          <span class="font-weight-bold d-block text-nowrap font-medium-1" style="height: 15px;">{{ itemlabel }}</span>
          <span class="text-muted font-small-2">{{ itemsublabel }}</span>
      </div>
    </div>
  </div>
  <div *ngIf="column.subType === 5">
    {{ itemlabel }}
  </div>
  <div *ngIf="column.subType === 6">
    <img [src]="itemlabel" style="width: 40px; height: 24px;"/>
  </div>
</div>
