<section id="ngx-datatable-Responsive">
    <div class="content-wrapper container-xxl p-0">
        <div class="content-body">
            <section class="users-list-wrapper">
                <div class="card mb-0">
                    <div class="row">
                        <div class="col-md-6 col-12 pt-1">
                            <div class="d-flex justify-content-between align-items-center m-1">
                                <h1 class="font-weight-normal"> {{title}}</h1>
                            </div>
                        </div>
                        <div *ngIf="showBtnAlti" class="col-md-6 col-12 d-flex justify-content-start justify-content-md-end p-1">
                            <div *ngIf="paginazione" class="d-flex justify-content-between align-items-center m-1">
                                <label class="d-flex align-items-center">Visualizza
                                    <select class="form-control mx-25"  (change)="showRow($event.target.value)">
                                        <option *ngFor="let numPage of pagination.pageSizes"  [selected]="numPage.toString() == pagination.pageSize.toString()"  [ngValue]="numPage"> {{numPage}} </option>
                                    </select>
                                </label>
                            </div>
                            <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pb-md-0">
                                <a *ngIf="showNewBtn" (click)="btnAddNew();" class="btn btn-primary ml-1" rippleEffect>
                                    <span class="d-none d-sm-inline-block"><i data-feather="plus" class="text-white"></i></span>
                                </a>
                                <a *ngIf="showExportBtn" class="btn btn-sm  btn-success ml-1" rippleEffect [routerLink]="" (click)="exportTableData(exportData)">
                                    Esporta Lista <span class="d-none d-sm-inline-block"><i data-feather="file-text" class="text-white"></i></span>
                                </a>
                                <button *ngIf="filtrable" class="btn btn-sm  btn-warning ml-1" rippleEffect (click)="removeFilters('')">
                                    Reset Filtri <span class="d-none d-sm-inline-block"><i data-feather="x-circle" class="text-white"></i></span>
                                </button>

                            </div>
                        </div>
                    </div>
                    <!--itemsPerPage: {{pagination.pageSize}} - currentPage: {{pagination.page}} - totalItems: {{pagination.count}} - Rowheight: {{pagination.rowHeight}} - limit: {{pagination.pageSize}}-->

                    <!-- <ngx-datatable [rows]="rows | paginate: { itemsPerPage: pagination.pageSize, currentPage: pagination.page,totalItems: pagination.count }" [rowHeight]="pagination.rowHeight" class="bootstrap core-bootstrap" [limit]="pagination.pageSize" [columnMode]="ColumnMode.force"
                        [headerHeight]="pagination.rowHeight" [footerHeight]="footerHeight" [scrollbarH]="true" [scrollbarV]="false" (page)="onPageChange($event)"> -->


                    <ngx-datatable
                        class="bootstrap core-bootstrap"
                        [rows]="rows | paginate: { itemsPerPage: pagination.pageSize, currentPage: pagination.page,totalItems: pagination.count }"
                        [columnMode]="ColumnMode.standard"
                        [rowHeight]="pagination.rowHeight"
                        [limit]="pagination.pageSize"
                        [headerHeight]="pagination.rowHeight"
                        [footerHeight]="footerHeight"
                        [scrollbarH]="true"
                        [scrollbarV]="false"
                        (page)="onPageChange($event)"
                    >
                        <ngx-datatable-column *ngIf="showActionBtn" name="" [canAutoResize]="false" [flexGrow]="1" [maxWidth]="30" [sortable]="false" prop="{{rows}}">
                            <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-row="row">
                                <div class="d-flex align-items-center">
                                    <div ngbDropdown container="body" *ngIf="rowActionBtn.length>0">
                                        <a ngbDropdownToggle href="javascript:void(0);" class="hide-arrow" id="dropdownBrowserState" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i data-feather="more-vertical" class="text-primary cursor-pointer mr-50"></i>
                                        </a>
                                        <div ngbDropdownMenu class="dropdown-menu-right" aria-labelledby="dropdownBrowserState">
                                            <div *ngFor="let btn of rowActionBtn" [ngSwitch]="btn.action.type">
                                                <!--type 1 link con url routerLink row[btn.action.idKey]-->
                                                <a *ngSwitchCase="1" (click)="show(btn.action.routeLink + row['key'])" ngbDropdownItem href="javascript:void(0)" class="d-flex align-items-center">
                                                    <i [data-feather]="btn.icon" class="{{btn.color}}  cursor-pointer mr-50"></i> {{btn.label}}
                                                </a>
                                                <!--type 2 link funzioni del componente interno (click)-->
                                                <a *ngSwitchCase="2" (click)="this[btn.action.function.funct](rowIndex,row.key,btn.action.function.params)" ngbDropdownItem href="javascript:void(0)" class="d-flex align-items-center">
                                                    <i [data-feather]="btn.icon" class="{{btn.color}}  cursor-pointer mr-50"></i> {{btn.label}}
                                                </a>
                                                <!--type 3 link funzioni custom extra da poter aggiungere nel table-action-service.ts (click)-->
                                                <a *ngSwitchCase="3" (click)="this._tableService[btn.action.function.funct](rowIndex,row.key,btn.action.function.params)" ngbDropdownItem href="javascript:void(0)" class="d-flex align-items-center">
                                                    <i [data-feather]="btn.icon" class="{{btn.color}}  cursor-pointer mr-50"></i> {{btn.label}}
                                                </a>
                                                <!--type 4 link con url routerLink row[btn.action.idKey] (DELETE) -->
                                                <a *ngSwitchCase="4" (click)="delete(row['key'])" ngbDropdownItem href="javascript:void(0)" class="d-flex align-items-center">
                                                    <i [data-feather]="btn.icon" class="{{btn.color}}  cursor-pointer mr-50"></i> {{btn.label}}
                                                </a>
                                                <!--type 13 an action to a rowbutton -->
                                                <a *ngSwitchCase="13" (click)="actionbutton(btn, row)" ngbDropdownItem href="javascript:void(0)" class="d-flex align-items-center">
                                                    <i [data-feather]="btn.icon" class="{{btn.color}}  cursor-pointer mr-50"></i> {{btn.label}}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>
    
                        <!-- <ngx-datatable-column [width]="colonna.width.replace('%','')" [canAutoResize]="true" [sortable]="false" *ngFor="let colonna of columns; let i = index;" name="{{colonna.title}}" prop="{{colonna.title}}"> -->
                        <!-- <ngx-datatable-column [canAutoResize]="true" [sortable]="false" *ngFor="let colonna of columns; let i = index;" name="{{colonna.title}}" prop="{{colonna.title}}"> -->
                        <!-- <ngx-datatable-column [maxWidth]="(colonna.width.replace('%','') === '0' ? 150 : colonna.width.replace('%',''))" [canAutoResize]="false" [sortable]="false" *ngFor="let colonna of columns; let i = index;" name="{{colonna.title}}" prop="{{colonna.title}}"> -->
                        <ngx-datatable-column [minWidth]="(colonna.width.replace('%','') === '0' ? 150 : colonna.width.replace('%',''))" [maxWidth]="(colonna.width.replace('%','') === '0' ? 150 : colonna.width.replace('%',''))" [canAutoResize]="false" [sortable]="false" *ngFor="let colonna of columns; let i = index;" name="{{colonna.title}}" prop="{{colonna.title}}">
                            <ng-template let-column="column" ngx-datatable-header-template>
                                <div class="d-flex align-items-center ">

                                    <div ngbDropdown container="body" *ngIf="colonna.searchable==true">
                                        <a *ngIf="colonna.searchable==true && filtriApplied[colonna.key]!=null" (click)="removeFilters(colonna)">
                                            <i [data-feather]="'x-circle'" [class]="'ficon cursor-pointer text-danger'"></i>
                                        </a>
                                        <a *ngIf="filtrable" ngbDropdownToggle href="javascript:void(0);" class="hide-arrow" id="dropdownBrowserState" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i data-feather="filter" [class]="(filtri[colonna.key]!=null) ? 'ficon cursor-pointer text-success' : 'ficon cursor-pointer' "></i>
                                        </a>
                                        &nbsp;

                                        <div ngbDropdownMenu class="dropdown-menu-center" aria-labelledby="dropdownBrowserState">
                                            <form #filterForm="ngForm" class="form" (ngSubmit)="filterBy(colonna,filterForm.value)">
                                                <div class="row p-2">
                                                    <div class="col-md-12">
                                                        <div class="row" *ngIf="colonna.filterDate">
                                                            <div class="col-12">
                                                                <div class="input-group w-0">
                                                                    <input readonly class="form-control" ngbDatepicker #datepicker="ngbDatepicker" [autoClose]="'outside'" (dateSelect)="onDateSelection($event,colonna.key, datepicker)" [displayMonths]="2" [dayTemplate]="rangeSelectionDP" outsideDays="hidden" [startDate]="fromDate!"
                                                                        style="display:none;" />
                                                                    <ng-template #rangeSelectionDP let-date let-focused="focused">
                                                                        <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)" [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
                                                                            {{ date.day }}
                                                                        </span>
                                                                    </ng-template>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-6">
                                                                        <div class="form-group text-center">
                                                                            <div class="input-group">
                                                                                <input readonly #dpFromDate class="form-control" type="text" (click)="datepicker.toggle()" placeholder="Dal" [value]="formatter.format(fromDate)" />
                                                                                <div class="input-group-append">
                                                                                    <button class="btn btn-outline-secondary feather icon-calendar" (click)="datepicker.toggle()" type="button" rippleEffect></button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6">
                                                                        <div class="form-group text-center">
                                                                            <div class="input-group">
                                                                                <input readonly #dpToDate class="form-control" type="text" (click)="datepicker.toggle()" placeholder="Al" [value]="formatter.format(toDate)" />
                                                                                <div class="input-group-append">
                                                                                    <button class="btn btn-outline-secondary feather icon-calendar" (click)="datepicker.toggle()" type="button" rippleEffect></button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group row" *ngIf="colonna.filterNumber">
                                                            <div class="col-12">
                                                                <div class="form-group">
                                                                    <div class="input-group input-group-merge">
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"><span [data-feather]="'search'"></span></span>
                                                                        </div>
                                                                        <input type="number" id="{{colonna.key}}" class="form-control" name="{{colonna.key}}" placeholder="Valore da ricercare" ngModel (input)="onFilterTextChange(colonna,$event.target.value)" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group row" *ngIf="colonna.filterText">
                                                            <div class="col-12">
                                                                <div class="form-group">
                                                                    <div class="input-group input-group-merge">
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"><span [data-feather]="'search'"></span></span>
                                                                        </div>
                                                                        <input type="text" id="{{colonna.key}}" class="form-control" name="{{colonna.key}}" placeholder="Testo da ricercare" ngModel (input)="onFilterTextChange(colonna,$event.target.value)" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group row" *ngIf="colonna.filterCheckBox">
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <div *ngFor="let checkbox of checkboxesDataList; let i = index;" class="custom-control custom-checkbox">
                                                                    <input type="checkbox" class="custom-control-input" name="{{colonna.key}}_{{i}}" id="{{colonna.key}}_{{i}}" (change)="changeSelection(colonna.key,checkbox,$event.target.checked)" />
                                                                    <label class="custom-control-label" for="{{colonna.key}}_{{i}}">{{checkbox.label}}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 text-center">
                                                        <h6 *ngIf="filtriApplied[colonna.key]" style="margin-bottom: 15px;">Filtro applicato: <strong>{{filtri[colonna.key]}}</strong></h6>

                                                        <button type="submit" rippleEffect class="btn btn-primary mr-1">Applica Filtro</button>
                                                        <button type="button" *ngIf="filtriApplied[colonna.key]" rippleEffect class="btn btn-danger mr-1" (click)="removeFilters(colonna)" data-toggle="dropdown">Reset Filtro</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div style="font-size: 10px;">
                                    <a *ngIf="colonna.sorter==true && filtrable" (click)="sortTable(colonna,colonna.key,colonna.dataIndex)">
                                        {{colonna.title}}
                                    </a>
                                    <a *ngIf="colonna.sorter==false ">
                                        {{colonna.title}}
                                    </a>
                                </div>

                                <a *ngIf="colonna.sorter==true && filtrable" (click)="sortTable(colonna,colonna.key,colonna.dataIndex)">
                                    <i [data-feather]="(lastSort.order==='descend' && lastSort.columnKey===colonna.key) ? 'chevron-up' : 'chevron-down'" [class]="'ficon cursor-pointer text-secondary'"></i>
                                </a>
                            </ng-template>

                            <ng-template let-value="value" let-row="row" let-rowIndex="rowIndex" let-expanded="expanded" ngx-datatable-cell-template>
                                <div>
                                    <a class="nav-link" style="font-size: 12px;" (click)="show(rowActionBtn[0].action.routeLink + row['key'])">
                                        <!--rowActionBtn[0].action.idKey-->
                                        <app-table-cell-formatter [row]="row" [column]="colonna" [parent]="this" [formatValue]="getFormattedValue"></app-table-cell-formatter>
                                    </a>
                                </div>

                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-footer>
                            <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize" let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
                                <datatable-pager [ngClass]="'d-flex justify-content-center pt-2'" [pagerLeftArrowIcon]="'datatable-icon-left'" [pagerRightArrowIcon]="'datatable-icon-right'" [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'" [page]="pagination.page"
                                    [size]="pagination.pageSize" [count]="pagination.count" (change)="onPageChange($event)">
                                </datatable-pager>
                            </ng-template>
                        </ngx-datatable-footer>
                    </ngx-datatable>
                </div>
                <div class="row text-center">
                    <div class="col-12">
                        <div class="badge badge-primary">
                            <i [data-feather]="'list'"></i> <strong> {{(pagination.count> (pagination.page*pagination.pageSize))?(pagination.page*pagination.pageSize):(pagination.count)}}</strong> di <strong>{{(pagination.count)}}</strong> risultati
                        </div>
                    </div>
                </div>
            </section>

            <!-- Modal ALERT -->
            <div class="modal-warning d-inline-block">
                <ng-template #modalWarning let-modal>
                    <div class="modal-header">
                    <h5 class="modal-title" id="myModalLabel160">{{ alertTitle }}</h5>
                    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="OK">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div class="modal-body" tabindex="0" ngbAutofocus>
                    {{ alertMessage }}
            
                    <div *ngIf="spinner" class="d-flex justify-content-center my-1">
                        <div class="spinner-border text-success" role="status"></div>
                    </div>
            
                    </div>
                    <div *ngIf="!spinner" class="modal-footer">
                    <button type="button" class="btn btn-{{ alertClass }}" (click)="modal.close('Accept click')" rippleEffect>
                        OK
                    </button>
                    </div>
                </ng-template>
            </div>
            <!-- / Modal -->  

        </div>
    </div>
</section>