import { Component, OnInit, Input } from '@angular/core';
import { restApiUrl } from '../../services/rest.service';

export const FORMAT_SUBTYPE_BADGE_RANGE:number = 1;
export const FORMAT_SUBTYPE_ICON:number = 2;
export const FORMAT_SUBTYPE_ICON_RANGE:number = 3;
export const FORMAT_SUBTYPE_AVATAR_TWO_LINES:number = 4;
export const FORMAT_SUBTYPE_CUSTOM_FORMAT:number = 5;
export const FORMAT_SUBTYPE_IMAGE:number = 6;

@Component({
  selector: 'app-table-cell-formatter',
  templateUrl: './table-cell-formatter.component.html',
  styleUrls: ['./table-cell-formatter.component.scss']
})
export class TableCellFormatterComponent implements OnInit {

  @Input() row : any = {};
  @Input() column : any = {};
  @Input() parent : Function;
  @Input() formatValue : Function;

  FORMAT_ACTION = 100; // Azione

  itemclass = '';
  itemlabel = '';
  itemicon = '';
  // avatar + due linee
  itemavatar = '';
  itemsublabel = '';

  constructor() { }

  ngOnInit(): void {
    if (this.column.formatType === this.FORMAT_ACTION) {
      var itemvalue:string = this.row[this.column.key];
      switch (this.column.subType) {
        case FORMAT_SUBTYPE_BADGE_RANGE:
        case FORMAT_SUBTYPE_ICON_RANGE:
          this.column.formatRange.forEach(element => {
            var match:boolean = false;
            if (element.value !== '') {
              if (itemvalue === element.value) {
                match = true;
              }
            } else { // usa min/max
              const valuenumber:number = parseFloat(itemvalue);
              if ((valuenumber >= element.min)&&(valuenumber <= element.max)) {
                match = true;
              }
            }
            if (match) {
              this.itemclass = element.color;
              this.itemlabel = element.label;
              this.itemicon = 'icon-' + element.icon;
            }
          });
          break;
        case FORMAT_SUBTYPE_ICON:
          this.itemicon = itemvalue;
          break;
        case FORMAT_SUBTYPE_AVATAR_TWO_LINES:
          if (this.column.formatRange.length > 0) {
            const element = this.column.formatRange[0];
            const lines = itemvalue.split(element.separator);
            this.itemclass = element.color;
            if (lines.length > 0) {
              this.itemlabel = lines[0];
            }
            if (lines.length > 1) {
              this.itemsublabel = lines[1];
            }
            if (lines.length > 2) {
              this.itemavatar = lines[2];
            }
          }
          break;  
        case FORMAT_SUBTYPE_CUSTOM_FORMAT:
          this.itemlabel = 'undefined !!!';
          if (this.formatValue !== null) {
            if (this.formatValue !== undefined) {
              this.itemlabel = this.formatValue(this.parent, this.column.title, itemvalue);
            }
          }
          break; 
        case FORMAT_SUBTYPE_IMAGE:
          if (itemvalue !== '') {
            this.itemlabel = restApiUrl + itemvalue;
          }
          break; 
        }
    }
  }

}
