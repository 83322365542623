<app-form-object tablename="-" keyname="-" formname="frmobj1" savemethod="putrecord" saveLabel="" backLabel="" [fields]="fields_frmobj1" (onFieldButtonClick)="onFieldButtonClick_frmobj1($event)" (onForeingKey)="onForeingKey_frmobj1($event)" (onSave)="onSavefrmobj1($event)" (onPreSave)="onPreSavefrmobj1($event)" (onPostRead)="onPostReadfrmobj1()" (onPreCreate)="onPreCreatefrmobj1($event)" #frmobj1></app-form-object>
<app-age-table [parametri]="parmsListOfPosts" [parent]="this" [getCellValue]="tblGetCustomCellForListOfPosts" (requestToJump)="Jump_tblobj2($event)" #tblobj2></app-age-table>

<!-- Modal ALERT -->
  <ng-template #modalAlert let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="myModalLabel160">{{ alertTitle }}</h5>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="OK">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
      {{ alertMessage }}

      <div *ngIf="spinner" class="d-flex justify-content-center my-1">
        <div class="spinner-border text-success" role="status"></div>
      </div>

    </div>
    <div *ngIf="!spinner" class="modal-footer">
      <button type="button" class="btn btn-{{ alertClass }}" (click)="modal.close('Accept click')" rippleEffect>
        OK
      </button>
    </div>
  </ng-template>
<!-- / Modal -->

