import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'app/auth/models';

export const LOGOFF_TIMEOUT:number = 20; // in minuti

@Injectable({
  providedIn: 'root'
})
export class AutoLogOffServiceService {
  
  //log off details
  isLogin = false;

  constructor(
      private router: Router,
      private ngZone: NgZone
  ) {

    // all'avvio esegui una pulizia di tutto
    //console.log('AutoLogOffServiceService.constructor: credentials cleanup...');
    localStorage.removeItem('currentUser');

    if(this.isUserLoggedIn()){
      this.isLogin=true;
    }
    this.lastAction(Date.now());
    this.check();
    this.initListener(); // tracciare i click !
    this.initInterval(); // controlla ogni secondo...
  }

  /**
     * last action
     */
  getLastAction() {
    return localStorage.getItem('lastAction');
  }

  /**
   * set last action
   * @param value
   */
  lastAction(value) {
    localStorage.setItem('lastAction', JSON.stringify(value))
  }

  /**
   * start event listener
   */
   initListener() {
    this.ngZone.runOutsideAngular(() => {
      document.body.addEventListener('click', () => this.reset());
      document.body.addEventListener('mousemove', () => this.reset());
    });
  }

  /**
   * time interval
   */
  initInterval() {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        this.check();
      }, 1000);
    })
  }

  /**
   * reset timer
   */
   reset() {
    this.lastAction(Date.now());
    //console.log('reset...');
  }

  login() {
    this.isLogin=true;
  }

  /**
   * check timer
   */
   check() {
    //console.log('AutoLogOffServiceService.check: checking...');
    const now = Date.now();
    const timeLeft = parseInt(this.getLastAction()) + (LOGOFF_TIMEOUT) * 60 * 1000;
    const diff = timeLeft - now;
    //console.log('AutoLogOffServiceService.check: timeLeft', timeLeft);
    //console.log('AutoLogOffServiceService.check: now', now);
    //console.log('AutoLogOffServiceService.check: diff', diff);
    const isTimeout = diff < 0;
    //this.isLoggedIn.subscribe(event => this.isLogin = event);
    this.ngZone.run(() => {
      if (isTimeout && this.isLogin) {
        localStorage.removeItem('lastAction');
        /*setTimeout(()=>{
          console.log("Your Session Expired due to longer Inactivity, Login Again To Continue");
        },10000);*/
        localStorage.removeItem('currentUser');
        this.isLogin=false;
        console.log('auto-log-off-service.service.ts: logout');
        this.router.navigate(['/pages/authentication/login-v2']);
      }
    });
  }

  /**
   *check if a user is logged in
   */
  isUserLoggedIn():string{
    var token = '';
    const currentUser:User = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser !== undefined) {
      if (currentUser !== null) {
        if (currentUser.token !== undefined) {
          if (currentUser.token !== null) {
            token = currentUser.token;
          }
        }
      }
    }
    //console.log('AutoLogOffServiceService.isUserLoggedIn: current user token = ', token);
    return token;
  }

  onLogout() {
    //console.log('AutoLogOffServiceService.onLogout: user logged out...');
    this.isLogin=false;
  }

}
