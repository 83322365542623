<span>
    <span *ngIf="rpt.type === 1" class="badge badge-pill badge-light-info mr-1">
        <i data-feather="code"></i>&nbsp;restapi
      </span>
      <span *ngIf="rpt.type === 2" class="badge badge-pill badge-light-primary mr-1">
        <i data-feather="type"></i>&nbsp;label
      </span>
      <span *ngIf="rpt.type === 3" class="badge badge-pill badge-light-warning mr-1">
        <i data-feather="image"></i>&nbsp;icon
      </span>
      <span *ngIf="rpt.type === 4" class="badge badge-pill badge-light-secondary mr-1">
        <i data-feather="bar-chart-2"></i>&nbsp;bar
      </span>
      <span *ngIf="rpt.type === 5" class="badge badge-pill badge-light-secondary mr-1">
        <i data-feather="trending-up"></i>&nbsp;area
      </span>
      <span *ngIf="rpt.type === 6" class="badge badge-pill badge-light-secondary mr-1">
        <i data-feather="activity"></i>&nbsp;line
      </span>
      <span *ngIf="rpt.type === 7" class="badge badge-pill badge-light-danger mr-1">
        <i data-feather="columns"></i>&nbsp;report
      </span>
      <span *ngIf="rpt.type === 8" class="badge badge-pill badge-light-success mr-1">
        <i data-feather="calendar"></i>&nbsp;dd/mm/yyyy
      </span>
    </span>