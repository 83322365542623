import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportQueryData, RestService } from '../services/rest.service';
import { User } from 'app/auth/models';

const POLICY_INTERNAL = 0; // non visibile all'utente
const POLICY_PUBLIC = 1; // visibile a tutti gli utenti
const POLICY_PRIVATE = 2; // visibile solo agli utenti abilitati

export interface Column {
  visible: boolean; // se visibile o no, la prima non è mai visibile
  widthPerc: number;
  sortBy: string;
  label: string;
  alignment: string; // left, right, center
  formatType: number; // formato vedi doc
  subType: number; // sub formato del formato SPECIALE (100)
  formatRange: any; // json con i range per il formato SPECIALE (100)
  filterType: number; // vedi doc
  filterBy: string; // vedi doc
  filterSpec: string; // vedi doc
  pageSize: number; // numero di righe per pagina, solo sul primo elemento
  defaultOrderBy: string; // ordine di default, solo sul primo elemento
  rowHeight: number; // ???
}

@Component({
  selector: 'app-report-editor',
  templateUrl: './report-editor.component.html',
  styleUrls: ['./report-editor.component.scss']
})
export class ReportEditorComponent implements OnInit {
  
  @ViewChild('modalNuovo') modalNuovo: ElementRef;
  @ViewChild('modalDanger') modalDanger: ElementRef;
  @ViewChild('modalCarica') modalCarica: ElementRef;
  @ViewChild('modalEditColumn') modalEditColumn: ElementRef;
  @ViewChild('modalSelectProduct') modalSelectProduct: ElementRef;
  @ViewChild('modalLoginProdotto') modalLoginProdotto: ElementRef;

  spinner: boolean = false;
  currentUser: User;
  alertTitle: string = '';
  alertMessage: string = '';
  alertClass: string = '';

  reporttype: string = '1';
  formattype: string = '0';
  formatsubtype: string = '0';
  filtertype: string = '0';
  widthperc: string = '0';

  reporticoncolor = '0';
  report = {
    policy: POLICY_PUBLIC,
    code: '',
    area: '',
    type: 1,
    title: '',
    icon: '',
    color: 0,
    sp: '',
    enabled: true,
    columns: [],
    source: {
      query: '',
    },
    filters: [],
    routePath: '',
    routePathParms: {
      code: '',
      description: '',
    },
  };

  newcolumn = false;
  column = {} as Column;

  reports = [];

  products = [];
  product:any = {};

  querydata = {} as ReportQueryData;

  routePathDescription = '';
  routePathDescription2 = '';

  constructor(
    private modalService: NgbModal,
    private rest: RestService,
    ) {
      this.querydata = {} as ReportQueryData;
      this.querydata.columns = [];
      this.querydata.data = [];
      let ps = localStorage.getItem('productSelected');
      if (ps !== null) {
        this.product = JSON.parse(ps);
      }
    }

  ngOnInit(): void {
  }

  btnOpenNuovo() {
    this.modalService.open(this.modalNuovo, {
      windowClass: 'modal'
    });  
  }

  btnNuovo() {
    this.report = {
      policy: POLICY_PUBLIC,
      code: 'codice_report',
      area: 'area',
      type: parseInt(this.reporttype),
      title: 'Titolo del report',
      icon: '',
      color: 0,
      sp: '',
      enabled: true,
      columns: [],
      source: {
        query: 'Scrivere qui la query sql...',
      },
      filters: [],
      routePath: '',
      routePathParms: {
        code: '',
        description: '',
      },
    };
    this.reporticoncolor = '' + this.report.color;
  }

  showAlert(alertType, title, message) {
    this.alertClass = alertType;
    this.alertTitle = title;
    this.alertMessage = message;
    this.modalService.dismissAll('Accept click');
    this.modalService.open(this.modalDanger, {
      backdrop: false,
      centered: true
    });  
  }

  btnCarica(modalNuovo) {
    /*this.modalService.open(modalNuovo, {
      backdrop: false,
      centered: true,
      size: 'lg', // size: 'xs' | 'sm' | 'lg' | 'xl'
      scrollable: true,
    });*/
    this.modalService.open(modalNuovo, {
      scrollable: true,
      size: 'xl',
    });
    this.spinner = true;

    this.reports = [];

    // preleva il token di login
    this.rest.callProductLogin(this.product.restapiurl, this.product.restapikey,
      this.product.adminuser, this.product.adminpassword)
    .subscribe(
      response => {
        if (response.success) {
          let producttoken = response.token;

          //this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
          // getallreportlist
          this.rest.callProductReports(this.product.restapiurl, this.product.restapikey, 
            producttoken, 'getallreportlist', '', {})
          .subscribe(
            response => {
              this.spinner = false;
              if (response.success) {
                if (response.reports.length === 0) {
                  this.showAlert('warning', 'ATTENZIONE', 'Non sono presenti reports !');
                } else {
                  this.reports = response.reports;
                }
              } else {
                this.showAlert('danger', 'ERRORE', response.error);
              }
            },
            error => {
              this.spinner = false
              this.showAlert('danger', 'ERRORE', error);
            }
          );

        } else {
          this.spinner = false;
          this.showAlert('danger', 'ERRORE', response.error);
        }
      },
      error => {
        this.spinner = false
        this.showAlert('danger', 'ERRORE', error);
      }
    );
  
  }

  btnCaricaClose() {
    console.log('btnCaricaClose');
  }

  btnSalva() {
    this.spinner = true;
    this.showAlert('success', 'Accesso in corso...', '');

    // preleva il token di login
    this.rest.callProductLogin(this.product.restapiurl, this.product.restapikey,
      this.product.adminuser, this.product.adminpassword)
    .subscribe(
      response => {
        if (response.success) {
          let producttoken = response.token;

          //this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
          this.rest.callProductReports(this.product.restapiurl, this.product.restapikey, 
            producttoken, 'uploadreport', this.report.code, this.report)
          .subscribe(
            response => {
              this.spinner = false;
              if (response.success) {
                this.showAlert('success', 'OK', 'Report salvato con successo !');
              } else {
                this.showAlert('danger', 'ERRORE', response.error);
              }
            },
            error => {
              this.spinner = false
              this.showAlert('danger', 'ERRORE', error);
            }
          );

        } else {
          this.spinner = false;
          this.showAlert('danger', 'ERRORE', response.error);
        }
      },
      error => {
        this.spinner = false
        this.showAlert('danger', 'ERRORE', error);
      }
    );

  }

  onCaricaRow(rpt) {
    this.modalService.dismissAll('Accept click');
    this.report = rpt;
    this.reporticoncolor = '' + this.report.color;
    this.setRoutePathDescription();
  }

  btnNewColumn() {
    this.column = {} as Column;
    this.column.visible = (this.report.columns.length > 0);
    this.column.widthPerc = 0;
    this.column.sortBy = '';
    this.column.label = (this.report.columns.length > 0 ? '' : 'Primary key');
    this.column.alignment = 'left';
    this.column.formatType = 0;
    this.column.subType = 0;
    this.column.formatRange = [];
    this.column.filterType = 0;
    this.column.filterBy = '';
    this.column.filterSpec = '';
    this.column.pageSize = 15;
    this.column.defaultOrderBy = '';
    this.column.rowHeight = 0;
    this.newcolumn = true;
    this.formattype = '' + this.column.formatType;
    this.formatsubtype = '' + this.column.subType;
    this.filtertype = '' + this.column.filterType;
    this.widthperc = '' + this.column.widthPerc;
    this.modalService.open(this.modalEditColumn, {
      backdrop: false,
      centered: true
    });
  }

  onColumnRow(col) {
    this.newcolumn = false;
    this.column = col;
    this.formattype = '' + this.column.formatType;
    this.formatsubtype = '' + this.column.subType;
    this.filtertype = '' + this.column.filterType;
    this.widthperc = '' + this.column.widthPerc;
    this.modalService.open(this.modalEditColumn, {
      backdrop: false,
      centered: true
    });
  }

  btnSaveColumn() {
    this.column.formatType = parseInt(this.formattype);
    this.column.subType = parseInt(this.formatsubtype);
    this.column.filterType = parseInt(this.filtertype);
    this.column.widthPerc = parseInt(this.widthperc);
    this.column.formatRange = [
      //{ min: 0, max: 10, value: '', color: 'bg-light-warning', icon: 'activity', label: 'male', separator: '\n'},
      //{ min: 11, max: 20, value: '', color: 'bg-light-success', icon: 'award', label: 'bene', separator: ','},
    ];
    if (this.newcolumn) {
      this.report.columns.push(this.column);
    }
  }

  btnEsegui() {
    this.querydata = {} as ReportQueryData;
    this.querydata.columns = [];
    this.querydata.data = [];

    this.spinner = true;
    this.showAlert('success', 'Accesso in corso...', '');

    // preleva il token di login
    this.rest.callProductLogin(this.product.restapiurl, this.product.restapikey,
      this.product.adminuser, this.product.adminpassword)
    .subscribe(
      response => {
        if (response.success) {
          let producttoken = response.token;

          this.rest.callProductExecuteReport(this.product.restapiurl, this.product.restapikey, 
            producttoken, this.report.code, 0, 15, {}, {}, {}, {})
          .subscribe(
            response => {
              this.spinner = false;
              this.showAlert('success', 'Report elaborato !', '');
              if (response.success) {
                this.querydata = response.querydata;
              } else {
                this.showAlert('danger', 'ERRORE', response.error);
              }
            },
            error => {
              this.spinner = false
              this.showAlert('danger', 'ERRORE', error);
            }
          );

        } else {
          this.spinner = false;
          this.showAlert('danger', 'ERRORE', response.error);
        }
      },
      error => {
        this.spinner = false
        this.showAlert('danger', 'ERRORE', error);
      }
    );

  }

  btnChooseProduct() {
    this.modalService.open(this.modalSelectProduct, {
      backdrop: false,
      centered: true,
      size: 'lg', // size: 'xs' | 'sm' | 'lg' | 'xl'
      scrollable: true,
    });
    this.spinner = true;

    this.products = [];

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.rest.callProducts(this.currentUser.token, 'get')
    .subscribe(
      response => {
        this.spinner = false;
        if (response.success) {
          if (response.products.length === 0) {
            this.showAlert('warning', 'ATTENZIONE', 'Non sono presenti prodotti !');
          } else {
            this.products = response.products;
          }
        } else {
          this.showAlert('danger', 'ERRORE', response.error);
        }
      },
      error => {
        this.spinner = false
        this.showAlert('danger', 'ERRORE', error);
      }
    );
  }

  onSelectProductRow(product) {
    this.modalService.dismissAll('Accept click');
    this.product = product;
    localStorage.setItem('productSelected', JSON.stringify(this.product));
    this.modalService.open(this.modalLoginProdotto, {
      backdrop: false,
      centered: true,
    });
  }

  btnLoginProdotto() {
    localStorage.setItem('productSelected', JSON.stringify(this.product));
  }

  onChangeIconColor() {
    this.report.color = parseInt(this.reporticoncolor);
  }

  btnElimina() {
    this.spinner = true;
    this.showAlert('success', 'Accesso in corso...', '');

    // preleva il token di login
    this.rest.callProductLogin(this.product.restapiurl, this.product.restapikey,
      this.product.adminuser, this.product.adminpassword)
    .subscribe(
      response => {
        if (response.success) {
          let producttoken = response.token;

          //this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
          this.rest.callProductReports(this.product.restapiurl, this.product.restapikey, 
            producttoken, 'deletereport', this.report.code, {})
          .subscribe(
            response => {
              this.spinner = false;
              if (response.success) {
                this.showAlert('success', 'OK', 'Report eliminato con successo !');
              } else {
                this.showAlert('danger', 'ERRORE', response.error);
              }
            },
            error => {
              this.spinner = false
              this.showAlert('danger', 'ERRORE', error);
            }
          );

        } else {
          this.spinner = false;
          this.showAlert('danger', 'ERRORE', response.error);
        }
      },
      error => {
        this.spinner = false
        this.showAlert('danger', 'ERRORE', error);
      }
    );

  }

  array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  }

  btnRowClickUp(element) {
    const idx = this.report.columns.indexOf(element);
    if (idx > 0) {
      this.array_move(this.report.columns, idx, idx - 1);
    }
  }

  btnRowClickDown(element) {
    const idx = this.report.columns.indexOf(element);
    if ((idx >= 0) && (idx < (this.report.columns.length - 1))) {
      this.array_move(this.report.columns, idx, idx + 1);
    }
  }

  btnRowClickDelete(element) {
    const idx = this.report.columns.indexOf(element);
    if (idx >= 0) {
      this.report.columns.splice(idx, 1);
    }
  }

  onChangeRoutePath() {
    this.setRoutePathDescription();
  }

  setRoutePathDescription() {
    if (this.report.routePath === '') {
        this.routePathDescription = 'Nessuna specifica';
        this.routePathDescription2 = 'Nessun specifica';
    } else if (this.report.routePath === 'RUN_REPORT') {
        this.routePathDescription = 'Specificare il CODICE del report...';
        this.routePathDescription2 = 'Specificare il titolo da visualizzare...';
    } else if (this.report.routePath === 'SHOW_AREA') {
        this.routePathDescription = 'Specificare l\'AREA da visualizzare...';
        this.routePathDescription2 = 'Specificare il nome del parametro filtro (valore prima colonna), Es. \'codice_sala\'';
    }
  }

}
