import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {

  public forcepk: any = {};

  public apartments = [];

  constructor() { }
}
