import { Injectable } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { FieldModel, validationType } from 'app/main/models/form-fields';

@Injectable({
  providedIn: 'root',
})
export class TableService {
  //INTERFACCIA-PROVIDER PER FUNCTION IN TABELLE
  stampa(index,key,params){
    console.log("button clicked",params);
  }

  
  
}