import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { CoreDirectivesModule } from '@core/directives/directives';
import { FormsModule } from '@angular/forms';

import { ReportEditorComponent } from '../report-editor/report-editor.component';
import { ReportFieldFormatComponent } from '../components/report-field-format/report-field-format.component';
import { ReportTypeComponent } from '../components/report-type/report-type.component';
@NgModule({
  declarations: [
    ReportEditorComponent,
    ReportFieldFormatComponent,
    ReportTypeComponent,
  ],
  imports: [
    NgbModule,
    CoreDirectivesModule,
    CommonModule,
    FormsModule,
    NgSelectModule,
  ],
  exports: []
})
export class ReportEditorModule { }
