import { Component, OnInit, Input, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { FieldTitle, FieldText, FieldNone, FieldDate, FieldButton, FieldCombo, FieldCheck } from '../guiobjects/field-object/field-object.component';
import { SysUsersListService } from './sys-users-list.service';
import { AgeTableComponent } from '../components/age-component/age-table/table/age-table.component';
import { FormObjectComponent } from '../guiobjects/form-object/form-object.component';
import { FieldObjectComponent } from '../guiobjects/field-object/field-object.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalsService } from '../services/globals.service';
import { Router } from '@angular/router'; 

@Component({
  selector: 'app-sys-users-list',
  templateUrl: './sys-users-list.component.html',
  styleUrls: ['./sys-users-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SysUsersListComponent implements OnInit {

  @ViewChild('modalAlert') modalAlert: ElementRef;

  foreignkey = '0';
  alertTitle: string = '';
  alertMessage: string = '';
  alertClass: string = '';

    tblobj38_parms = {
   "rowActionBtn": [{
      "color": "text-primary",
      "icon": "clipboard",
      "action": {
         "routeLink": "/sysusermodify/",
         "type": 1
      },
      "label": "Modifica Utente"
   }],
   "routeLink": "/sysusercreate/",
   "showNewBtn": true,
   "showBtnAlti": true,
   "title": "Elenco utenti",
   "restApi": {
      "method": "userslist",
      "apiParams": {"fk": "0"}
   },
   "showActionBtn": true
};
  @ViewChild('tblobj38') tblobj38: AgeTableComponent;


  constructor(
    private service: SysUsersListService,
    private router: Router,
    public globals: GlobalsService,
    private modalService: NgbModal,
  ) {
    service.component = this;
    if(this.router.getCurrentNavigation().extras.state !== undefined){
      const state = this.router.getCurrentNavigation().extras.state;
      if (state.params !== undefined) {
        this.foreignkey = state.params.apiParams.fk;
      }
    } 

    

  }

  ngOnInit(): void {

  }

  showAlert(alertType, title, message) {
    this.alertClass = alertType;
    this.alertTitle = title;
    this.alertMessage = message;
    this.modalService.dismissAll('Accept click');
    this.modalService.open(this.modalAlert, {
      backdrop: false,
      centered: true
    });  
  }

    tbluserslist(): AgeTableComponent {
    return this.tblobj38;
  }
  refresh_tbluserslist() {
    this.tbluserslist().onPageChange({page: 1});
  }

  tblobj38_routing = null;
  Jump_tblobj38(routing) {
    this.router.navigate(routing.commands, routing.extras);
  }


}

