import { Injectable } from '@angular/core';
import { SysUserCreateComponent } from './sys-user-create.component';
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { User } from 'app/auth/models';
import { RestService, restApiUrl, restApiKey } from '../services/rest.service';

@Injectable({
  providedIn: 'root'
})
export class SysUserCreateService {

  component: SysUserCreateComponent = null;
  currentUser: User;

  constructor(
    private rest: RestService,
    private http: HttpClient,
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }


  callRestAPI_(request:any): Observable<any> {
    return this.http.post<any>(restApiUrl, request, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
      })
    });
  }

  callRestAPI(request:any, successCallback, errorCallBack = undefined) {
    this.callRestAPI_(request).subscribe(
      response => {
        // aggiorna il token
        this.currentUser.token = response.token;
        localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
        if (response.success) {
          successCallback(response);
        } else {
          if (errorCallBack !== undefined) {
            errorCallBack(response.error);
          } else {
            this.component.showAlert('danger', 'ERRORE', response.error);
          }
        }
      },
      error => {
        if (errorCallBack !== undefined) {
          errorCallBack(error);
        } else {
          this.component.showAlert('danger', 'ERRORE', error);
        }
      }
    );
  }

  /*
  DOCUMENTAZIONE:
  
  OGGETTO: FORM
    Action: onPreSave, viene chiamata una funzione qui con il parametro parms che contiene:
      { record, jump } dove record contiene i campi validati del form e jump che deve essere passato senza modifiche.
      La funzione deve restituire sempre il parametro parms con parms.success = true se si vuole
      procedere al salvataggio oppure parms.success = false se non si vuole salvare.
      Eventuali messaggi di errore devono essere gestiti qui.

  */

  //===> BEGIN - Inizio funzioni dello sviluppatore

  onCreateUser(parms) {
    const request = {
      apikey: restApiKey,
      method: 'admin',
      action: 'create_user',
      name: parms.record.name,
      surname: parms.record.surname,
      username: parms.record.username,
      email: parms.record.email,
      token: this.currentUser.token,
    };
    this.callRestAPI(request, function (response) {
      this.component.goBack();
    });

    parms.success = false; // la restapi è asincrona quindi dai sempre il false
    return parms;
  }

  //===> END - Fine funzioni dello sviluppatore

}

