<app-form-object tablename="" keyname="pk" formname="frmobj39" savemethod="putrecord" saveLabel="Salva" backLabel="Indietro" [fields]="fields_frmobj39" (onFieldButtonClick)="onFieldButtonClick_frmobj39($event)" (onForeingKey)="onForeingKey_frmobj39($event)" (onSave)="onSavefrmobj39($event)" (onPreSave)="onPreSavefrmobj39($event)" (onPostRead)="onPostReadfrmobj39()" #frmobj39></app-form-object>

<!-- Modal ALERT -->
  <ng-template #modalAlert let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="myModalLabel160">{{ alertTitle }}</h5>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="OK">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
      {{ alertMessage }}

      <div *ngIf="spinner" class="d-flex justify-content-center my-1">
        <div class="spinner-border text-success" role="status"></div>
      </div>

    </div>
    <div *ngIf="!spinner" class="modal-footer">
      <button type="button" class="btn btn-{{ alertClass }}" (click)="modal.close('Accept click')" rippleEffect>
        OK
      </button>
    </div>
  </ng-template>
<!-- / Modal -->

