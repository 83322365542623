import { Injectable } from '@angular/core';
import { EditPostComponent } from './edit-post.component';
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { User } from 'app/auth/models';
import { RestService, restApiUrl, restApiKey } from '../services/rest.service';

@Injectable({
  providedIn: 'root'
})
export class EditPostService {

  component: EditPostComponent = null;
  currentUser: User;

  constructor(
    private rest: RestService,
    private http: HttpClient,
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

  }


  callRestAPI_(request:any): Observable<any> {
    return this.http.post<any>(restApiUrl, request, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
      })
    });
  }

  callRestAPI(request:any, successCallback, errorCallBack = undefined, context = null) {
    this.callRestAPI_(request).subscribe(
      response => {
        // aggiorna il token
        this.currentUser.token = response.token;
        localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
        if (response.success) {
          successCallback(response, context);
        } else {
          if (errorCallBack !== undefined) {
            errorCallBack(response.error);
          } else {
            this.component.showAlert('danger', 'ERRORE', response.error);
          }
        }
      },
      error => {
        if (errorCallBack !== undefined) {
          errorCallBack(error);
        } else {
          this.component.showAlert('danger', 'ERRORE', error);
        }
      }
    );
  }

  /*
  DOCUMENTAZIONE:
  
  OGGETTO: FORM
    Action: onPreSave, viene chiamata una funzione qui con il parametro parms che contiene:
      { record, jump } dove record contiene i campi validati del form e jump che deve essere passato senza modifiche.
      La funzione deve restituire sempre il parametro parms con parms.success = true se si vuole
      procedere al salvataggio oppure parms.success = false se non si vuole salvare.
      Eventuali messaggi di errore devono essere gestiti qui.

  */

  //===> BEGIN - Inizio funzioni dello sviluppatore

  pk = 0;
  getActualPost(): string {
    const routeParams = this.component.route.snapshot.paramMap;
    const pk_parm = routeParams.get('pk');
    if (pk_parm === null) {
      this.pk = 0;
    } else {
      this.pk = Number(routeParams.get('pk'));
    }
    return '' + this.pk;
  }

  onPostRead() {
    localStorage.setItem('pkpost', this.getActualPost());
    this.component.refresh_tblRelatedPosts();
  }

  getFkparent() {
    var fkparent = localStorage.getItem('fkparent');
    if (fkparent === null) {
      fkparent = '0';
    }
    if (fkparent === undefined) {
      fkparent = '0';
    }
    return fkparent;
  }

  onPreCreate(context) {
    // imposta il campo fk in creazione nuovo post
    context.setFieldValue('fk', this.getFkparent());
  }

  //===> END - Fine funzioni dello sviluppatore

}

