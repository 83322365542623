import { Component, OnInit, Input, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { FieldTitle, FieldText, FieldNone, FieldDate, FieldButton, FieldCombo, FieldCheck } from '../guiobjects/field-object/field-object.component';
import { PostsConfiguratorService } from './posts-configurator.service';
import { AgeTableComponent } from '../components/age-component/age-table/table/age-table.component';
import { FormObjectComponent } from '../guiobjects/form-object/form-object.component';
import { FieldObjectComponent } from '../guiobjects/field-object/field-object.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalsService } from '../services/globals.service';
import { Router, ActivatedRoute } from '@angular/router'; 

@Component({
  selector: 'app-posts-configurator',
  templateUrl: './posts-configurator.component.html',
  styleUrls: ['./posts-configurator.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PostsConfiguratorComponent implements OnInit {

  @ViewChild('modalAlert') modalAlert: ElementRef;

  foreignkey = '0';
  alertTitle: string = '';
  alertMessage: string = '';
  alertClass: string = '';

  
  @ViewChild('frmobj1') frmobj1: FormObjectComponent;
  public fields_frmobj1 = [];
  parmsListOfPosts = {
   "rowActionBtn": [
      {
         "color": "text-primary",
         "icon": "edit",
         "action": {
            "routeLink": "/editpost/",
            "type": 1
         },
         "label": "Modifica"
      },
      {
         "color": "text-primary",
         "icon": "corner-up-right",
         "action": {
            "routeLink": "postGoTo",
            "type": 13
         },
         "label": "Vai a"
      },
      {
         "color": "text-primary",
         "icon": "copy",
         "action": {
            "routeLink": "duplicatePost",
            "type": 13
         },
         "label": "Duplica post"
      },
      {
         "color": "text-primary",
         "icon": "align-justify",
         "action": {
            "routeLink": "duplicatePosts",
            "type": 13
         },
         "label": "Duplica posts"
      },
      {
         "color": "text-primary",
         "icon": "arrow-up",
         "action": {
            "routeLink": "postUp",
            "type": 13
         },
         "label": "Su"
      },
      {
         "color": "text-primary",
         "icon": "arrow-down",
         "action": {
            "routeLink": "postDown",
            "type": 13
         },
         "label": "Giù"
      }
   ],
   "routeLink": "/editpost/",
   "showNewBtn": true,
   "showBtnAlti": true,
   "title": "Elenco post",
   "restApi": {
      "method": "ListOfPosts",
      "apiParams": {
         "fkparent": "0",
         "fk": "0"
      }
   },
   "showActionBtn": true
};
  @ViewChild('tblobj2') tblobj2: AgeTableComponent;


  constructor(
    private service: PostsConfiguratorService,
    public router: Router,
    public route: ActivatedRoute,
    public globals: GlobalsService,
    private modalService: NgbModal,
  ) {
    service.component = this;
    if(this.router.getCurrentNavigation().extras.state !== undefined){
      const state = this.router.getCurrentNavigation().extras.state;
      if (state.params !== undefined) {
        this.foreignkey = state.params.apiParams.fk;
      }
    } 

    
this.fields_frmobj1.push(new FieldButton({"readonly":false,"btncolor":"btn-primary","name":"buttonFiltra","icon":"home","defaultvalue":"","numberMask":0,"label":"Home","validations":[],"span":2}));
    this.service.setFkParent();


  }

  ngOnInit(): void {

  }

  showAlert(alertType, title, message) {
    this.alertClass = alertType;
    this.alertTitle = title;
    this.alertMessage = message;
    this.modalService.dismissAll('Accept click');
    this.modalService.open(this.modalAlert, {
      backdrop: false,
      centered: true
    });  
  }

  
  setFieldValue_frmobj1(fieldname, value) {
    var campo = {};
    campo[fieldname] = value;
    this.frmobj1.myForm.patchValue(campo);
  }

  frmButtons(): FormObjectComponent {
    return this.frmobj1;
  }

  onFieldButtonClick_frmobj1(item) {
    if (item.field.name === 'buttonFiltra') {
      this.service.btnGoToHome();
    }

  }

  onForeingKey_frmobj1(keyspec) {
  }

  onSavefrmobj1(response) {
  }

  onPreSavefrmobj1(parms) {

    this.frmobj1.saveRecordImmediate(parms.record, parms.jump);
  }

  onPostReadfrmobj1() {
  }

  onPreCreatefrmobj1(context) {
  }
  tblListOfPosts(): AgeTableComponent {
    return this.tblobj2;
  }
  refresh_tblListOfPosts() {
    let flt_fkparent = this.service.getFkparent();
    if (flt_fkparent === undefined) {
      flt_fkparent = '0';
    } else if (flt_fkparent === null) {
      flt_fkparent = '0';
    } else if (flt_fkparent === '') {
      flt_fkparent = '0';
    }
    this.parmsListOfPosts.restApi.apiParams['fkparent'] = flt_fkparent;
    this.tblListOfPosts().onPageChange({page: 1});
  }

  tblobj2_routing = null;
  Jump_tblobj2(routing) {
    if (routing.commands !== undefined) {
      this.router.navigate(routing.commands, routing.extras);
    }
    if (routing.button !== undefined) {
      if (routing.button.label === 'Vai a') {
        this.service.postGoTo(routing.data);
      }
      if (routing.button.label === 'Duplica post') {
        this.service.duplicatePost(routing.data);
      }
      if (routing.button.label === 'Duplica posts') {
        this.service.duplicatePosts(routing.data);
      }
      if (routing.button.label === 'Su') {
        this.service.postUp(routing.data);
      }
      if (routing.button.label === 'Giù') {
        this.service.postDown(routing.data);
      }
    }
  }

  tblGetCustomCellForListOfPosts(parent: any, columnLabel:string, columnValue:string): string {
    // in parent c'è questa stessa classe perché this è il child !
    var value:string = '';
    if (columnLabel === 'Appartamenti') {
      value = parent.service.getAppartamenti(columnValue);
    }

    return value;
  }


}

