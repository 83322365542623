import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';

import { CoreCommonModule } from '@core/common.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { CoreSidebarModule } from '@core/components';

import { AgeTableComponent } from './table/age-table.component';

//Service rest Api
import { RestService } from '../../../../main/services/rest.service';

//form component
import { FormsComponent } from '../age-forms/forms/forms.component';
import { FieldComponent } from '../age-forms/field/field.component';

// cell formatter
import { TableCellFormatterComponent } from '../../../guiobjects/table-cell-formatter/table-cell-formatter.component';


// routing
const routes: Routes = [
  {
    path: 'table',
    component: AgeTableComponent,
    resolve: {
      uls: RestService
    },
    data: { animation: 'AgeTableComponent' }
  },
];

@NgModule({
  declarations: [AgeTableComponent, FormsComponent, FieldComponent, TableCellFormatterComponent], //, UserFormComponent
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreCommonModule,
    FormsModule,
    NgbModule,
    NgSelectModule,
    Ng2FlatpickrModule,
    NgxDatatableModule,
    NgxPaginationModule,
    CorePipesModule,
    CoreDirectivesModule,
    CoreSidebarModule
  ],
  exports: [AgeTableComponent, FormsComponent, FieldComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [RestService]
})

export class AgeTableModule {}

