<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <!-- content-header component -->
        <app-content-header [contentHeader]="contentHeader"></app-content-header>

        <!-- Basic Alerts start -->
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Benvenuto in Hello Host !</h4>
                        </div>
                        <div class="card-body pb-1">
                            <p class="card-text">Da qui puoi configurare le varie funzioni del prodotto Hello Host</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Basic Alerts end -->
    </div>
</div>