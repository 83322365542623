import { Component, OnInit, Input, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { FieldTitle, FieldText, FieldNone, FieldDate, FieldButton, FieldCombo, FieldCheck } from '../guiobjects/field-object/field-object.component';
import { SysProfileListService } from './sys-profile-list.service';
import { AgeTableComponent } from '../components/age-component/age-table/table/age-table.component';
import { FormObjectComponent } from '../guiobjects/form-object/form-object.component';
import { FieldObjectComponent } from '../guiobjects/field-object/field-object.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalsService } from '../services/globals.service';
import { Router } from '@angular/router'; 

@Component({
  selector: 'app-sys-profile-list',
  templateUrl: './sys-profile-list.component.html',
  styleUrls: ['./sys-profile-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SysProfileListComponent implements OnInit {

  @ViewChild('modalAlert') modalAlert: ElementRef;

  foreignkey = '0';
  alertTitle: string = '';
  alertMessage: string = '';
  alertClass: string = '';

    tblobj40_parms = {
   "rowActionBtn": [{
      "color": "text-primary",
      "icon": "clipboard",
      "action": {
         "routeLink": "/sysprofile/",
         "type": 1
      },
      "label": "Modifica"
   }],
   "routeLink": "/sysprofile/",
   "showNewBtn": true,
   "showBtnAlti": true,
   "title": "Elenco profili",
   "restApi": {
      "method": "profilelist",
      "apiParams": {"fk": "0"}
   },
   "showActionBtn": true
};
  @ViewChild('tblobj40') tblobj40: AgeTableComponent;


  constructor(
    private service: SysProfileListService,
    private router: Router,
    public globals: GlobalsService,
    private modalService: NgbModal,
  ) {
    service.component = this;
    if(this.router.getCurrentNavigation().extras.state !== undefined){
      const state = this.router.getCurrentNavigation().extras.state;
      if (state.params !== undefined) {
        this.foreignkey = state.params.apiParams.fk;
      }
    } 

    

  }

  ngOnInit(): void {

  }

  showAlert(alertType, title, message) {
    this.alertClass = alertType;
    this.alertTitle = title;
    this.alertMessage = message;
    this.modalService.dismissAll('Accept click');
    this.modalService.open(this.modalAlert, {
      backdrop: false,
      centered: true
    });  
  }

    tblprofilelist(): AgeTableComponent {
    return this.tblobj40;
  }
  refresh_tblprofilelist() {
    this.tblprofilelist().onPageChange({page: 1});
  }

  tblobj40_routing = null;
  Jump_tblobj40(routing) {
    this.router.navigate(routing.commands, routing.extras);
  }


}

