import { Component, OnInit, Input, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { FieldTitle, FieldText, FieldNone, FieldDate, FieldButton, FieldCombo, FieldCheck } from '../guiobjects/field-object/field-object.component';
import { EditRelPostService } from './edit-rel-post.service';
import { AgeTableComponent } from '../components/age-component/age-table/table/age-table.component';
import { FormObjectComponent } from '../guiobjects/form-object/form-object.component';
import { FieldObjectComponent } from '../guiobjects/field-object/field-object.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalsService } from '../services/globals.service';
import { Router, ActivatedRoute } from '@angular/router'; 

@Component({
  selector: 'app-edit-rel-post',
  templateUrl: './edit-rel-post.component.html',
  styleUrls: ['./edit-rel-post.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditRelPostComponent implements OnInit {

  @ViewChild('modalAlert') modalAlert: ElementRef;

  foreignkey = '0';
  alertTitle: string = '';
  alertMessage: string = '';
  alertClass: string = '';

  
  @ViewChild('frmobj5') frmobj5: FormObjectComponent;
  public fields_frmobj5 = [];


  constructor(
    private service: EditRelPostService,
    public router: Router,
    public route: ActivatedRoute,
    public globals: GlobalsService,
    private modalService: NgbModal,
  ) {
    service.component = this;
    if(this.router.getCurrentNavigation().extras.state !== undefined){
      const state = this.router.getCurrentNavigation().extras.state;
      if (state.params !== undefined) {
        this.foreignkey = state.params.apiParams.fk;
      }
    } 

    
this.fields_frmobj5.push(new FieldNone({"readonly":false,"btncolor":"","name":"hostpk","icon":"","defaultvalue":"3","numberMask":0,"label":"-","validations":[],"span":12}));
this.fields_frmobj5.push(new FieldNone({"readonly":false,"btncolor":"","name":"fk","icon":"","defaultvalue":"0","numberMask":0,"label":"-","validations":[],"span":12}));
this.fields_frmobj5.push(new FieldNone({"readonly":false,"btncolor":"","name":"fklang","icon":"","defaultvalue":"0","numberMask":0,"label":"-","validations":[],"span":12}));
this.fields_frmobj5.push(new FieldCombo({"readonly":false,"btncolor":"","name":"lang","icon":"","options":[{"key":"en","c2":"Inglese"},{"key":"it","c2":"Italiano"},{"key":"pl","c2":"Polacco"},{"key":"es","c2":"Spagnolo"},{"key":"fr","c2":"Francese"},{"key":"de","c2":"Tedesco"},{"key":"xx","c2":"Nascosto"}],"multiple":false,"defaultvalue":"en","numberMask":0,"label":"Lingua","validations":[],"span":2}));
this.fields_frmobj5.push(new FieldText({"readonly":false,"btncolor":"","name":"title","icon":"","defaultvalue":"","numberMask":0,"label":"Titolo","validations":[],"span":10}));
this.fields_frmobj5.push(new FieldText({"readonly":false,"btncolor":"","name":"subtitle","icon":"","defaultvalue":"","numberMask":0,"label":"Sottotitolo","validations":[],"span":12}));
this.fields_frmobj5.push(new FieldText({"readonly":false,"btncolor":"","name":"body","icon":"","defaultvalue":"","numberMask":0,"label":"Contenuto","validations":[],"span":12}));


  }

  ngOnInit(): void {

  }

  showAlert(alertType, title, message) {
    this.alertClass = alertType;
    this.alertTitle = title;
    this.alertMessage = message;
    this.modalService.dismissAll('Accept click');
    this.modalService.open(this.modalAlert, {
      backdrop: false,
      centered: true
    });  
  }

  
  setFieldValue_frmobj5(fieldname, value) {
    var campo = {};
    campo[fieldname] = value;
    this.frmobj5.myForm.patchValue(campo);
  }

  frmPost(): FormObjectComponent {
    return this.frmobj5;
  }

  onFieldButtonClick_frmobj5(item) {

  }

  onForeingKey_frmobj5(keyspec) {
  }

  onSavefrmobj5(response) {
  }

  onPreSavefrmobj5(parms) {

    this.frmobj5.saveRecordImmediate(parms.record, parms.jump);
  }

  onPostReadfrmobj5() {
  }

  onPreCreatefrmobj5(context) {

    this.service.onPreCreate(context);
  }


}

