import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild, Input, SimpleChanges, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router, ActivatedRouteSnapshot } from '@angular/router';
import { NgForm,FormGroup, Validators, FormControl, FormBuilder, ValidationErrors, FormArray, ValidatorFn } from '@angular/forms';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { cloneDeep } from 'lodash';
import { Location } from '@angular/common';
import { FieldModel, functionType, validationType } from '../../../../models/form-fields';
import { HttpClient } from '@angular/common/http';
import { ConsoleService } from '@ng-select/ng-select/lib/console.service';

import { RestService } from '../../../../../main/services/rest.service';
import { FormService } from '../forms-action-service';

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss'],
  encapsulation: ViewEncapsulation.None
})


export class FormsComponent implements OnInit, OnDestroy, AfterViewInit {  
  public url = this.router.url;
  public urlLastValue;
  public avatarImage: string;
  public fGroup = {};
  public fields: FieldModel[];
  public myForm: FormGroup;
  public submitted: boolean;
  public localConfig: any;
  public params : any;
  public validationTypes :any;
  public id:any;
  public formIsValid:boolean;

  public extraButtonActions:any=[];
  public extraFunctionsOnInit:any=[];
  public extraFunctionBeforeSubmit:any=[];

  public error = '';
  value: string;
  
  @Input() parametri? : any = {};
  @Input() formFunzioniAggiuntive? : any = [];

  @ViewChild('customForm') customForm: NgForm;

  private _unsubscribeAll: Subject<any>;

  constructor(private formsService:FormService, private route: ActivatedRoute,private router: Router, private _AgeService: RestService, private location: Location ,private httpClient:HttpClient,private formBuilder: FormBuilder) {
    this._unsubscribeAll = new Subject();
    this.urlLastValue = this.url.substr(this.url.lastIndexOf('/') + 1);
    this.localConfig = JSON.parse(localStorage.getItem('currentUser'));
    this.id=this.route.snapshot.params['id'];
    this.formIsValid=true;
  }
  
  strutturaCampi(){
    var t=this;
    if(typeof t.parametri.jsonFields == 'string'){
      //DA FILE JSON
      t.httpClient.get(t.parametri.jsonFields)
      .pipe(map((fields: FieldModel[])=>{
        return fields.map(field=>{
          return new FieldModel(field)
        })
      }))
      .subscribe((fields: any)=>{
        t.fields = fields;
        this.myForm = t.getFormGroup(t.fields);//se ordinamento: t.getFormGroup(t.fields.sort((a,b) => a.order -b.order));
      });
    }
    else{
        //DA OGGETTO
        t.fields=t.parametri.jsonFields;
        this.myForm = t.getFormGroup(t.fields);//se ordinamento: t.getFormGroup(t.fields.sort((a,b) => a.order -b.order));
    }
  }

  //DEFINIZIONE DELLE VALIDAZIONI
  getFormGroup(fields: FieldModel[]){
    var t=this;
    fields.forEach(field=>{
      if(field.validationType!=undefined){
        var validazioni = (field.validationType!=undefined) ? this.formsService.setValidation(field.validationType) : [];
        t.fGroup[field.name] = new FormControl(field.value,validazioni);
      }
    });
    return new FormGroup(t.fGroup);
  }

  //SUBMIT DEL FORM IN CASO ANCHE FUNZIONI AGGIUNTIVE
  submit() {
    if(this.extraFunctionBeforeSubmit.length>0){
      for (let f of this.extraFunctionBeforeSubmit) {
          this.formsService[f.funct](f.params);
      }
    }
    this.submitted=true;
    if (this.myForm.valid) {
      this.formsService.save(this.myForm.getRawValue());
      console.log('Submitted...!'); // salvare i dati
    }
    
  }

  goBack(){
    this.location.back(); 
  }

  getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }

  async loadDatiFromApi(id){
    if(this.parametri.restApi.jsonIn[this.parametri.restApi.idName]!=undefined)
    this.parametri.restApi.jsonIn[this.parametri.restApi.idName] = id;
    this._AgeService.ageDataFromRestApi(JSON.stringify(this.parametri.restApi.jsonIn))
        .subscribe(
      response => {
        if (response.success) {
          this.myForm.patchValue(response[this.parametri.restApi.outputNode][0]);
        } else {
          this.error = response.error;
        }
      },
      error => {
        this.error = error
      }
    );
  }

  
  ngOnInit(): void {
    this.myForm = new FormGroup({});

    if(this.formFunzioniAggiuntive.length>0){
      this.extraFunctionsOnInit=this.formFunzioniAggiuntive.filter(k => k.type === '0');
      this.extraButtonActions=this.formFunzioniAggiuntive.filter(k => k.type === '1');
      this.extraFunctionBeforeSubmit=this.formFunzioniAggiuntive.filter(k => k.type === '2');
 
    }

    if(this.extraFunctionsOnInit.length>0){
      for (let f of this.extraFunctionsOnInit) {
          this.formsService[f.funct](f.params);
      }
    }

    this.strutturaCampi(); 
    
    //New or edit intercetto id url
    if(this.id!=undefined && this.id!=null){
      this.loadDatiFromApi(this.id);
    }
   
  }

  ngAfterViewInit(): void {}

  
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}