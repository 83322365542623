import { Component, OnInit, ViewChild } from '@angular/core';
import { TreeModel } from '@circlon/angular-tree-component';

import { menu } from '../../menu/menu';

@Component({
  selector: 'app-tree-profile',
  templateUrl: './tree-profile.component.html',
  styleUrls: ['./tree-profile.component.scss']
})
export class TreeProfileComponent implements OnInit {
  
  @ViewChild('treeObject') treeObject: TreeModel;
  
  public nodesCheckbox = [];

  constructor() { }

  ngOnInit(): void {
    this.nodesCheckbox = [];

    menu.forEach(element => {
      let menuitem = {
        name: '',
        children: [],
        isExpanded: true,
      };

      if (element.type === 'collapsible') {
        menuitem.name = element.title;
        element.children.forEach(subelement => {
          menuitem.children.push({ name: subelement.title });
        });
        menuitem.isExpanded = true;
      } else if (element.type === 'section') {
        menuitem.name = element.title;
      } else if (element.type === 'item') {
        menuitem.name = element.title;
      }

      this.nodesCheckbox.push(menuitem);
    });

  }

}
