import { CoreMenu } from '@core/types'

export const menu: CoreMenu[] = [
  {
    id: 'home',
    title: 'Home',
    translate: 'Home',
    type: 'item',
    icon: 'home',
    url: 'home'
  },
  {
    id: 'reporteditor',
    title: 'Report Editor',
    translate: 'Report Editor',
    type: 'item',
    icon: 'layout',
    url: 'reporteditor',
    role: ['Admin'],
  },

  //===> BEGIN - Inizio menu dello sviluppatore
{
  "role": ["Admin"],
  "icon": "config",
  "id": "postsconfigurator",
  "type": "item",
  "title": "Configurazione categorie",
  "translate": "Configurazione categorie",
  "url": "postsconfigurator"
},
  //===> END - Fine menu dello sviluppatore

];
