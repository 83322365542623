<div class="form-group" [formGroup]="form">

    <div [ngSwitch]="fType">
        <!--title-->
        <div *ngSwitchCase="'title'">
            <h4 class="mb-1">
                <i [data-feather]="field.icon" [size]="field.size" [ngClass]="field.classiCss"></i>
                &nbsp;<span class="align-middle">{{field.label}}</span>
            </h4>
        </div>

        <!--text field-->
        <div *ngSwitchCase="'textbox'">
            <label [attr.for]="field.name">{{field.label}}</label>
            <input [ngClass]="field.classiCss" [formControlName]="field.name" [value]="field.value" [readonly]="field.readonly" type="text" class="form-control" id="{{field.name}}" name="{{field.name}}">
        </div>


        <!--hidden field-->
        <div *ngSwitchCase="'hidden'">
            <input [ngClass]="field.classiCss" [formControlName]="field.name" [value]="field.value" [readonly]="field.readonly" [ngModelOptions]="{standalone: true}" id="{{field.name}}" name="{{field.name}}" type="hidden" class="form-control">
        </div>

        <!--text number-->
        <div *ngSwitchCase="'number'">
            <label [attr.for]="field.name">{{field.label}}</label>
            <input [ngClass]="field.classiCss" [formControlName]="field.name" [value]="field.value" [readonly]="field.readonly" [id]="field.name" name="{{field.name}}" type="number" class="form-control">
        </div>

        <!--textarea-->
        <div *ngSwitchCase="'textarea'">
            <label [attr.for]="field.name">{{field.label}}</label>
            <textarea rows="5" [ngClass]="field.classiCss" [formControlName]="field.name" [value]="field.value" [readonly]="field.readonly" [ngModelOptions]="{standalone: true}" id="{{field.name}}" name="{{field.name}}" class="form-control">{{field.value}}</textarea>
        </div>

        <!--select-->
        <div *ngSwitchCase="'dropdown'">
            <label [attr.for]="field.name">{{field.label}} </label>
            <select [id]="field.name" [ngClass]="field.classiCss" [formControlName]="field.name" [value]="field.value" class="form-control">
                <option *ngFor="let opt of field.options" [value]="opt.value" >{{opt.value}}</option>
            </select>
        </div>

        <!--select multipla con chips-->
        <div *ngSwitchCase="'multiselect'">
            <label [attr.for]="field.name">{{field.label}}</label>
            <ng-select [ngClass]="field.classiCss" [formControlName]="field.name" [items]="selectMulti | async" [multiple]="true" [closeOnSelect]="false" [searchable]="true" bindLabel="label" [(ngModel)]="selectMultiSelected" (change)="field.value=selectMultiSelected">
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                    <span class="ng-value-label"> {{ item.label }}</span>
                    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                </ng-template>
            </ng-select>
        </div>

        <!--radio-->
        <div *ngSwitchCase="'radio'">
            <label class="d-block mb-1" [attr.for]="field.name">{{field.label}}</label>
            <div class="custom-control custom-radio custom-control-inline">
                <div *ngFor="let opt of field.options" class="custom-control custom-radio custom-control-inline">
                    <input type="radio" [ngClass]="field.classiCss" [formControlName]="field.name" [readonly]="field.readonly" [id]="opt.value" [value]='opt.value' [checked]="field.value == opt.value ? true : false">
                    <label class="custom-control-label" [attr.for]="opt.value">{{opt.value}}</label>
                </div>
            </div>
        </div>

        <!--date picker classico-->
        <div *ngSwitchCase="'datetime'">
            <label [attr.for]="field.name">{{field.label}}</label>
            <ng2-flatpickr [ngClass]="field.classiCss" [formControlName]="field.name" [readonly]="field.readonly" [config]="DateOptions" [value]="field.value" [id]="field.name"></ng2-flatpickr>
        </div>

        <!--date picker range-->
        <div *ngSwitchCase="'datetimerange'">
            <label [attr.for]="field.name">{{field.label}}</label>
            <ng2-flatpickr [ngClass]="field.classiCss" [formControlName]="field.name" [readonly]="field.readonly" [config]="DateRangeOptions" [value]="field.value" [id]="field.name" name="{{field.name}}"></ng2-flatpickr>
        </div>

        <!--date picker mese anno-->
        <div *ngSwitchCase="'datetimemy'">
            <label [attr.for]="field.name">{{field.label}}</label>
            <ng2-flatpickr [ngClass]="field.classiCss" [formControlName]="field.name" [readonly]="field.readonly" [config]="MYDateOptions" [value]="field.value" [id]="field.name"></ng2-flatpickr>
        </div>

        <!--time picker-->
        <div *ngSwitchCase="'timepicker'">
            <label [attr.for]="field.name">{{field.label}}</label>
            <ngb-timepicker [ngClass]="field.classiCss" [formControlName]="field.name" [readonly]="field.readonly" [(ngModel)]="basicTP" [id]="field.name"></ngb-timepicker>
        </div>

        <!--checkbox-->
        <div *ngSwitchCase="'checkbox'">
            <label class="d-block mb-1" [attr.for]="field.name">{{field.label}}</label>
            <div *ngFor="let opt of field.options" class="custom-control custom-checkbox custom-control-inline">
                <input type="checkbox" [ngClass]="field.classiCss" [formControlName]="field.name" [readonly]="field.readonly" class="custom-control-input" [id]="opt.value" [value]='opt.value' [checked]="field.value == opt.value ? true : false" />
                <label class="custom-control-label" [attr.for]="opt.value">{{opt.value}} </label>
            </div>
        </div>

    </div>
    <!--ERRORI VALIDAZIONE MESSAGGIO-->
    <div class="errorMessage text-danger" *ngIf="!valido">{{errMsg}}</div>

</div>