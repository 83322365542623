
import { environment } from 'environments/environment';

export class Pagination {
  page:number;
  count:number;
  pageSize:number;
  pageSizes:number[];
  rowHeight:number;

  constructor(
    page?:number,
    count?:number,
    pageSize?:number,
    pageSizes?:number[],
    rowHeight?:number
  )
  {
    this.page=page;
    this.count=count;
    this.pageSize=pageSize;
    this.pageSizes=pageSizes;
    this.rowHeight=rowHeight;
  }
}

