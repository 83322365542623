import { Component, OnInit, Input, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { FieldTitle, FieldText, FieldNone, FieldDate, FieldButton, FieldCombo, FieldCheck } from '../guiobjects/field-object/field-object.component';
import { EditPostService } from './edit-post.service';
import { AgeTableComponent } from '../components/age-component/age-table/table/age-table.component';
import { FormObjectComponent } from '../guiobjects/form-object/form-object.component';
import { FieldObjectComponent } from '../guiobjects/field-object/field-object.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalsService } from '../services/globals.service';
import { Router, ActivatedRoute } from '@angular/router'; 

@Component({
  selector: 'app-edit-post',
  templateUrl: './edit-post.component.html',
  styleUrls: ['./edit-post.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditPostComponent implements OnInit {

  @ViewChild('modalAlert') modalAlert: ElementRef;

  foreignkey = '0';
  alertTitle: string = '';
  alertMessage: string = '';
  alertClass: string = '';

  
  @ViewChild('frmobj3') frmobj3: FormObjectComponent;
  public fields_frmobj3 = [];
  parmsRelatedPosts = {
   "rowActionBtn": [{
      "color": "text-primary",
      "icon": "clipboard",
      "action": {
         "routeLink": "/editrelpost/",
         "type": 1
      },
      "label": "Modifica"
   }],
   "routeLink": "/editrelpost/",
   "showNewBtn": true,
   "showBtnAlti": true,
   "title": "Post correlati",
   "restApi": {
      "method": "RelatedPosts",
      "apiParams": {
         "fkpost": "0",
         "fk": "0"
      }
   },
   "showActionBtn": true
};
  @ViewChild('tblobj4') tblobj4: AgeTableComponent;


  constructor(
    private service: EditPostService,
    public router: Router,
    public route: ActivatedRoute,
    public globals: GlobalsService,
    private modalService: NgbModal,
  ) {
    service.component = this;
    if(this.router.getCurrentNavigation().extras.state !== undefined){
      const state = this.router.getCurrentNavigation().extras.state;
      if (state.params !== undefined) {
        this.foreignkey = state.params.apiParams.fk;
      }
    } 

    
this.fields_frmobj3.push(new FieldNone({"readonly":false,"btncolor":"","name":"hostpk","icon":"","defaultvalue":"3","numberMask":0,"label":"-","validations":[],"span":12}));
this.fields_frmobj3.push(new FieldCombo({"btncolor":"","icon":"","multiple":false,"defaultvalue":"","numberMask":0,"label":"Categoria","apiParams":{},"readonly":false,"name":"fk","options":[],"restapiMethod":"cmbCategoria","validations":[],"span":12}));
this.fields_frmobj3.push(new FieldCombo({"readonly":false,"btncolor":"","name":"lang","icon":"","options":[{"key":"en","c2":"Inglese"},{"key":"it","c2":"Italiano"},{"key":"pl","c2":"Polacco"},{"key":"es","c2":"Spagnolo"},{"key":"fr","c2":"Francese"},{"key":"de","c2":"Tedesco"},{"key":"xx","c2":"Nascosto"}],"multiple":false,"defaultvalue":"en","numberMask":0,"label":"Lingua","validations":[],"span":2}));
this.fields_frmobj3.push(new FieldText({"readonly":false,"btncolor":"","name":"title","icon":"","defaultvalue":"","numberMask":0,"label":"Titolo","validations":[],"span":10}));
this.fields_frmobj3.push(new FieldText({"readonly":false,"btncolor":"","name":"subtitle","icon":"","defaultvalue":"","numberMask":0,"label":"Sottotitolo","validations":[],"span":12}));
this.fields_frmobj3.push(new FieldText({"readonly":false,"btncolor":"","name":"body","icon":"","defaultvalue":"","numberMask":0,"label":"Contenuto","validations":[],"span":12}));
this.fields_frmobj3.push(new FieldCombo({"btncolor":"","icon":"","multiple":true,"defaultvalue":"","numberMask":0,"label":"Appartamenti","apiParams":{},"readonly":false,"name":"fkroom","options":[],"restapiMethod":"ListOfRooms","validations":[],"span":12}));
this.fields_frmobj3.push(new FieldText({"readonly":false,"btncolor":"","name":"link_phone","icon":"","defaultvalue":"","numberMask":0,"label":"Telefono","validations":[],"span":6}));
this.fields_frmobj3.push(new FieldText({"readonly":false,"btncolor":"","name":"link_map","icon":"","defaultvalue":"","numberMask":0,"label":"Mappa","validations":[],"span":6}));
this.fields_frmobj3.push(new FieldText({"readonly":false,"btncolor":"","name":"link_www","icon":"","defaultvalue":"","numberMask":0,"label":"Sito","validations":[],"span":6}));
this.fields_frmobj3.push(new FieldText({"readonly":false,"btncolor":"","name":"link_youtube","icon":"","defaultvalue":"","numberMask":0,"label":"YouTube","validations":[],"span":6}));
this.fields_frmobj3.push(new FieldCombo({"readonly":false,"btncolor":"","name":"tags","icon":"","options":[{"key":"1","c2":"Prenotazione"},{"key":"2","c2":"Apri portone"},{"key":"3","c2":"Codice Key Box"},{"key":"10","c2":"Codice Door Lock antiorario"},{"key":"11","c2":"Codice Door Lock orario"},{"key":"4","c2":"WiFi"},{"key":"5","c2":"Ask To Host"},{"key":"6","c2":"Documenti PS"},{"key":"7","c2":"Link Mappa"},{"key":"8","c2":"Link YouTube"},{"key":"9","c2":"Titolo Sovrapposto"}],"multiple":true,"defaultvalue":"","numberMask":0,"label":"Tags","validations":[],"span":6}));


  }

  ngOnInit(): void {

  }

  showAlert(alertType, title, message) {
    this.alertClass = alertType;
    this.alertTitle = title;
    this.alertMessage = message;
    this.modalService.dismissAll('Accept click');
    this.modalService.open(this.modalAlert, {
      backdrop: false,
      centered: true
    });  
  }

  
  setFieldValue_frmobj3(fieldname, value) {
    var campo = {};
    campo[fieldname] = value;
    this.frmobj3.myForm.patchValue(campo);
  }

  frmPost(): FormObjectComponent {
    return this.frmobj3;
  }
  refreshComboSql_frmPost_fk() {
    let filtervalue = '';
    let filterfield: any = {};
    let ref: FieldObjectComponent = null;
  }
  refreshComboSql_frmPost_fkroom() {
    let filtervalue = '';
    let filterfield: any = {};
    let ref: FieldObjectComponent = null;
  }

  onFieldButtonClick_frmobj3(item) {

  }

  onForeingKey_frmobj3(keyspec) {
  }

  onSavefrmobj3(response) {
  }

  onPreSavefrmobj3(parms) {

    this.frmobj3.saveRecordImmediate(parms.record, parms.jump);
  }

  onPostReadfrmobj3() {

    this.service.onPostRead();
  }

  onPreCreatefrmobj3(context) {

    this.service.onPreCreate(context);
  }
  tblRelatedPosts(): AgeTableComponent {
    return this.tblobj4;
  }
  refresh_tblRelatedPosts() {
    let flt_fkpost = this.service.getActualPost();
    if (flt_fkpost === undefined) {
      flt_fkpost = '0';
    } else if (flt_fkpost === null) {
      flt_fkpost = '0';
    } else if (flt_fkpost === '') {
      flt_fkpost = '0';
    }
    this.parmsRelatedPosts.restApi.apiParams['fkpost'] = flt_fkpost;
    this.tblRelatedPosts().onPageChange({page: 1});
  }

  tblobj4_routing = null;
  Jump_tblobj4(routing) {
    if (routing.commands !== undefined) {
      this.router.navigate(routing.commands, routing.extras);
    }
    if (routing.button !== undefined) {
    }
  }

  tblGetCustomCellForRelatedPosts(parent: any, columnLabel:string, columnValue:string): string {
    // in parent c'è questa stessa classe perché this è il child !
    var value:string = '';

    return value;
  }


}

