<div class="tab-pane" id="formFields">
  <form class="form-validate" [formGroup]="myForm" (ngSubmit)="submit()" method="post" novalidate>
    <div class="row">
        <!--COMPONENTE CAMPI CHE FANNO PARTE DEL FORM-->
        <div *ngFor="let field of fields" class="col-md-{{field.span}}">
          <app-field-object [parentFormGroup]="myForm" [attributes]="field" (onButtonClick)="onButtonClick($event, field)" (onCreateObject)="onCreateObject($event)"></app-field-object>
        </div>
    </div>
  </form>
  <div class="row mt-1">
    <div class="col-12 d-flex flex-sm-row flex-column mt-2">
        <!--BOTTONE SALVA-->
        <!-- <button type="button" (click)="submit()" [disabled]="!myForm.valid" class="btn btn-primary mb-1 mb-sm-0 mr-0 mr-sm-1" rippleEffect>Salva </button> -->
        <button *ngIf="saveLabel !== ''" type="button" (click)="submit()" class="btn btn-primary mb-1 mb-sm-0 mr-0 mr-sm-1" rippleEffect>{{ saveLabel }}</button>
        <!--BOTTONE BACK-->
        <button *ngIf="backLabel !== ''" type="reset" class="btn btn-outline-secondary" rippleEffect (click)="goBack()">{{ backLabel }}</button>
    </div>
  </div>
<!-- Modal ALERT -->
  <ng-template #modalAlert let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="myModalLabel160">{{ alertTitle }}</h5>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="OK">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
      {{ alertMessage }}

      <div *ngIf="spinner" class="d-flex justify-content-center my-1">
        <div class="spinner-border text-success" role="status"></div>
      </div>

    </div>
    <div *ngIf="!spinner" class="modal-footer">
      <button type="button" class="btn btn-{{ alertClass }}" (click)="modal.close('Accept click')" rippleEffect>
        OK
      </button>
    </div>
  </ng-template>
  <!-- / Modal -->
</div>
