import { Injectable } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { FieldModel, validationType } from 'app/main/models/form-fields';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  //INTERFACCIA-PROVIDER PER FUNCTION IN FORM
  clickFunction(params){
    console.log("button clicked",params);
  }

  clickFunction2(){
    console.log("button clicked 2222");
  }


  beforeSubmit(params){
    console.log("beforeSubmit",params);
  }


  functionOnInit(params){
    console.log("functionOnInit",params);
  }

  fieldFunctionchange(params){
    console.log("fieldFunctionchange",params);
  }
  

  save(params){
    console.log("Salvo i dati: ",params);
  }


  setValidation(types) : ValidatorFn[]{
    var validationArray = [];
    types.forEach((item) => {
      switch(item.vtype) { 
        case 0: { 
            //required
            validationArray.push(Validators.required);
            break; 
        } 
        //campo email
        case 1: { 
            validationArray.push(Validators.email) ;
            break; 
        } 
        //campo maxlenght
        case 2: { 
            validationArray.push(Validators.maxLength(item.vrule));
            break; 
        } 
        //campo minlenght
        case 3: { 
            validationArray.push(Validators.minLength(item.vrule));
            break; 
        } 
        //campo pattern
        case 4: { 
            validationArray.push(Validators.pattern(item.vrule)); //[A-Za-z]{5} es. AAAdd
            break; 
        } 
        default: { 
            validationArray = [];
            break; 
        } 
      } 
    });
    
    return validationArray; 
  }
  
}