// ENUMS:
// https://www.typescriptlang.org/docs/handbook/enums.html
// https://dev.to/shane/working-with-enums-in-angular-html-templates-2io9

import { Component, OnInit, Input, HostListener, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FlatpickrOptions } from 'ng2-flatpickr';
import Italian from 'flatpickr/dist/l10n/it';
import { fieldType } from 'app/main/models/form-fields';
import { Observable, of } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';
import { ReportQueryData, RestService, restApiUrl, restApiKey } from '../../services/rest.service';
import { User } from 'app/auth/models';

export enum FieldType {
  None = 0, // indefinito (non viene visualizzato)
  Text = 1, // campo di testo
  Numeric = 2, // campo numerico
  Date = 3, // campo data
  DateTime = 3, // campo data e ora
  DateRange = 4, // campo range di date
  Combo = 5, // campo combo normale (elenco in "options" oppure SQL in "querysql")
  ComboNumericLJB = 6, // campo combo LJB con valore numerico (SQL in "querysql" dove verrà effettuata una sostituzione di stringa con i tag: @@searchtext@@ contenente i caratteri digitati sulla combo dall'utente e @@and_code_equal@@ nel caso si voglia limitare il numero di records restituiti quando viene specificato un valore iniziale)
  ComboNumeric = 7, // campo combo con valore numerico (elenco in "options" oppure SQL in "querysql")
  ComboTextLJB = 8, // campo combo LJB con valore stringa (SQL in "querysql" dove verrà effettuata una sostituzione di stringa con i tag: @@searchtext@@ contenente i caratteri digitati sulla combo dall'utente e @@and_code_equal@@ nel caso si voglia limitare il numero di records restituiti quando viene specificato un valore iniziale)
  ComboText = 9, //campo combo con valore stringa (elenco in "options" oppure SQL in "querysql")
  TagNumeric = 12, // campo TAG con valore numerico (elenco in "options" oppure SQL in "querysql")
  TagString = 13, // campo TAG con valore stringa (elenco in "options" oppure SQL in "querysql")
  DateMonthYear = 14, // campo mese-anno
  DateMonthYearRange = 15, //campo range mese-anno
  Title = 1000, // titolo, non corrisponde a nessun campo
  Button = 1001, // bottone, non corrisponde a nessun campo
  CheckBox = 1002, // check box 1/0
  //radio button, poi vediamo
}

export class FieldNone {
  type: FieldType = FieldType.None;
  span: number = 12;
  defaultvalue: string = '';
  value: string = '';
  constructor(values: Object = {}) {
    Object.assign(this, values);    
  }
}

export class FieldButton {
  label: string = 'specificare il titolo'; // bottone
  type: FieldType = FieldType.Button;
  span: number = 12;
  icon: string = 'layout';
  size: number = 24;
  classiCss: string = '';
  constructor(values: Object = {}) {
    Object.assign(this, values);    
  }
}

export class FieldTitle {
  label: string = 'specificare il titolo'; // titolo
  type: FieldType = FieldType.Title;
  span: number = 12;
  icon: string = 'layout';
  size: number = 24;
  classiCss: string = '';
  fontSize: string = '';
  fontColor: string = '';
  constructor(values: Object = {}) {
    Object.assign(this, values);    
  }
}

export class FieldText {
  label: string = 'specificare l\'etichetta'; // etichetta del campo
  name: string; // nome del campo su db
  type: FieldType = FieldType.Text;
  value: string = '';
  defaultvalue: string = '';
  validations: [];
  span: number = 2;
  readonly: boolean = false;
  classiCss: string = '';
  icon: string = '';
  constructor(values: Object = {}) {
    Object.assign(this, values);
    if (this.validations === undefined) {
      this.validations = [];
    }
    this.value = this.defaultvalue;
  }
}

export class FieldDate {
  label: string = 'specificare l\'etichetta'; // etichetta del campo
  name: string; // nome del campo su db
  type: FieldType = FieldType.Date;
  value: string = '';
  defaultvalue: string = '';
  validations: [];
  span: number = 2;
  readonly: boolean = false;
  classiCss: string = '';
  icon: string = '';
  // https://flatpickr.js.org/examples/
  dateOptions: FlatpickrOptions = {
    altInput: true,
    //altFormat: 'd/m/Y  H:i:S',
    altFormat: 'd/m/Y',
    locale: Italian.it,
  };
  constructor(values: Object = {}) {
    Object.assign(this, values);
    if (this.validations === undefined) {
      this.validations = [];
    }
    this.value = '';
    this.dateOptions.locale = Italian.it; // forza Italiano
  }
}

export class FieldDateTime {
  label: string = 'specificare l\'etichetta'; // etichetta del campo
  name: string; // nome del campo su db
  type: FieldType = FieldType.Date;
  value: string = '';
  defaultvalue: string = '';
  validations: [];
  span: number = 2;
  readonly: boolean = false;
  classiCss: string = '';
  icon: string = '';
  // https://flatpickr.js.org/examples/
  dateOptions: FlatpickrOptions = {
    altInput: true,
    //altFormat: 'd/m/Y  H:i:S',
    altFormat: 'd/m/Y',
    locale: Italian.it,
  };
  constructor(values: Object = {}) {
    Object.assign(this, values);
    if (this.validations === undefined) {
      this.validations = [];
    }
    this.value = '';
    this.dateOptions.locale = Italian.it; // forza Italiano
  }
}

export class FieldCheck {
  label: string = 'specificare l\'etichetta'; // etichetta del campo
  name: string; // nome del campo su db
  type: FieldType = FieldType.CheckBox;
  value: string = '';
  defaultvalue: string = '0';
  validations: [];
  span: number = 2;
  readonly: boolean = false;
  classiCss: string = '';
  icon: string = '';
  constructor(values: Object = {}) {
    Object.assign(this, values);
    if (this.validations === undefined) {
      this.validations = [];
    }
    this.value = this.defaultvalue;
  }
}

export interface OptionItem {
  id: string;
  label: string;
}

export class FieldCombo {
  label: string = 'specificare l\'etichetta'; // etichetta del campo
  name: string; // nome del campo su db
  type: FieldType = FieldType.ComboText;
  value: string = '';
  defaultvalue: string = '';
  validations: [];
  span: number = 2;
  readonly: boolean = false;
  classiCss: string = '';
  icon: string = '';
  options: [];
  constructor(values: Object = {}) {
    Object.assign(this, values);
    if (this.validations === undefined) {
      this.validations = [];
    }
    this.value = this.defaultvalue;
  }
}

@Component({
  selector: 'app-field-object',
  templateUrl: './field-object.component.html',
  styleUrls: ['./field-object.component.scss']
})
export class FieldObjectComponent implements OnInit {

  @Input() attributes : any = {};
  @Input() parentFormGroup : FormGroup;
  @ViewChild('flatpickerbasic') flatpickerbasic: ElementRef;
  @Output() onButtonClick = new EventEmitter<any>();
  @Output() onCreateObject = new EventEmitter<any>();

  eFieldType = FieldType;
  errorControl = null;
  isRequired = false;
  currentUser: User;
  //selectBasic: OptionItem[] = [];
  selectBasic: [] = [];
  selectBasicLoading = false;

  @HostListener('change', ['$event']) onChange(event) { 
    //console.log('field ' + this.attributes.name + ', event', event);
    if (this.attributes.type === FieldType.Date) {
      this.attributes.ref = this.flatpickerbasic;
      if (this.attributes.ref !== undefined) {
        const valuechanged = this.attributes.ref.flatpickr.valuechanged;
        //console.log('questo è il valuechanged', valuechanged);
        //console.log('questo è il value', this.attributes.value);
        if (!this.attributes.value.startsWith(valuechanged)) {
          this.attributes.value = this.attributes.ref.flatpickr.valuechanged + ' 00:00:00';
          //console.log('ok lo cambio, adesso questo è il nuovo valore', this.attributes.value);
          // aggiorno il valore sul form group
          var campo = {};
          campo[this.attributes.name] = this.attributes.value;
          this.parentFormGroup.patchValue(campo);
        } else {
          //console.log('non faccio nessun cambiamento');
        }  
      }
    }
    event.preventDefault();
  }

  @HostListener('keyup', ['$event']) inputChanged(event) {
    //console.log('field ' + this.attributes.name + ', event', event);
    event.preventDefault();
  }

  constructor(
    private rest: RestService,
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }

  ngAfterViewInit(): void {
    if (this.onCreateObject !== undefined) {
      if (this.onCreateObject !== null) {
        this.onCreateObject.emit(this);
      }
    }
  }
  
  ngOnInit(): void {
    this.initOptions();

    //console.log('this.attributes', this.attributes);
    if (this.attributes.type === FieldType.Date) {
      this.attributes.dateOptions.onChange = function(selectedDates, dateStr, instance): void {
        //console.log('onChange: this', this);
        this.valuechanged = dateStr;
        //console.log('onChange: this.valuechanged', this.valuechanged);
      };
    }

    switch (this.attributes.type) {
      case this.eFieldType.Text:
        this.errorControl = this.parentFormGroup.controls[this.attributes.name];
        this.attributes.validations.forEach(validation => {
          if (validation.type === 'required') {
            this.isRequired = true;
          }
        });
        break;
      case this.eFieldType.Date:
        this.errorControl = this.parentFormGroup.controls[this.attributes.name];
        this.attributes.validations.forEach(validation => {
          if (validation.type === 'required') {
            this.isRequired = true;
          }
        });
        break;
      case this.eFieldType.ComboText:
        this.errorControl = this.parentFormGroup.controls[this.attributes.name];
        this.attributes.validations.forEach(validation => {
          if (validation.type === 'required') {
            this.isRequired = true;
          }
        });
        break;
    }
  }

  fieldButtonClick() {
    if (this.onButtonClick !== undefined) {
      if (this.onButtonClick !== null) {
        this.onButtonClick.emit({
          field: this.attributes,
          item: this.attributes,
        });
      }
    }
  }

  // select basic
  private initOptions() {
    if (this.attributes.restapiMethod === undefined) {
      this.selectBasicLoading = true;
      this.getItems().subscribe(x => {
        this.selectBasic = x;
        this.selectBasicLoading = false;
      });
    } else {
      this.getReportsrecords();
    }
  }

  getItemList() {
    return this.attributes.options;
  }

  getItems(term: string = null): Observable<[]> {
    let items = this.getItemList();
    if (term) {
      items = items.filter(x => x.label.toLocaleLowerCase().indexOf(term.toLocaleLowerCase()) > -1);
    }
    return of(items).pipe(delay(50));
  }

  getReportsrecords() {

    this.selectBasicLoading = true;
    this.rest.callProductExecuteReport(restApiUrl, restApiKey, 
      this.currentUser.token, this.attributes.restapiMethod, 0, -1, this.attributes.apiParams, {}, {}, {})
    .subscribe(
      response => {
        this.selectBasicLoading = false;
        if (response.success) {
          this.selectBasic = response.querydata.data;
        } else {
          console.log('ERRORE', response.error);
        }
      },
      error => {
        this.selectBasicLoading = false;
        console.log('ERRORE', error);
      }
    );

  }

  onComboChange(selecteditem) {
    if (this.onButtonClick !== undefined) {
      if (this.onButtonClick !== null) {
        this.onButtonClick.emit({
          field: this.attributes,
          item: selecteditem,
        });
      }
    }
  }

  onCheckBoxChange(event) {
    if (this.attributes.value === '1') {
      this.attributes.value = '0';
    } else {
      this.attributes.value = '1';
    }
    if (this.onButtonClick !== undefined) {
      if (this.onButtonClick !== null) {
        this.onButtonClick.emit({
          field: this.attributes,
          item: this.attributes,
        });
      }
    }
  }

}
