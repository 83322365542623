import { Component, OnInit, Input, Injectable, HostListener, ElementRef, Directive } from '@angular/core';
import { FieldModel, validationType,fieldType } from '../../../../models/form-fields';
import { AbstractControl, FormBuilder, FormControl, FormGroup,ValidatorFn, Validators } from '@angular/forms';
import { FlatpickrOptions, } from 'ng2-flatpickr';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { FormService } from '../forms-action-service';

@Injectable()

@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'] 
})
export class FieldComponent implements OnInit {
  @Input() field: any;
  @Input() form?: FormGroup | null= null;

  @HostListener('keyup', ['$event']) inputChanged(event) {
    this.validatore(this.field,event.target.value);
    if(this.field.functions!=undefined && this.field.functions.event === 'keyup' ){
      this.formsService[this.field.functions.function](this.field.functions.params)
    }
    event.preventDefault();
  }

  @HostListener('change', ['$event']) onChange(event) { 
    this.validatore(this.field,event.target.value);
    if(this.field.functions!=undefined && this.field.functions.event === 'change' ){
      this.formsService[this.field.functions.function](this.field.functions.params)
    }
    event.preventDefault();
  }

  @HostListener('focusout', ['$event']) onBlur(event) {
    this.validatore(this.field,event.target.value);
    if(this.field.functions!=undefined && this.field.functions.event === 'focusout' ){
      this.formsService[this.field.functions.function](this.field.functions.params)
    }
    event.preventDefault();
  }
  

  public valido : boolean;
  public errMsg : string;
  public validationTypes :any;
  public fType : any;
  public upper:boolean;
  public campo:any={};
  public dataOdierna :any = Date();
  public classiCss:any=[];
  public maxLen:any;
  public minLen:any;

  public selectMulti: Observable<any[]>;
  public selectMultiSelected = [];
  public submitted = false;
  
  //datetime classico
  public DateOptions: FlatpickrOptions = {
    altInput: true,
    altFormat: 'Y-m-d',
    //minDate: "today",
    //maxDate: new Date().fp_incr(14) ,
    //disable: ["2025-01-30", "2025-02-21", "2025-03-08", new Date(2025, 4, 9) ],
  }; 

  //daterange
  public DateRangeOptions: FlatpickrOptions = {
    altInput: true,
    mode: 'range',
    altFormat: 'Y-m-d',
    locale: {
        rangeSeparator: ' '
    }
  };

  //datetime mese anno
  public MYDateOptions: FlatpickrOptions = {
    altInput: true,
    shorthand: true,
    dateFormat: "m.y",  
    altFormat: "m Y",  
    plugins: []
  };


  //Time Picker
  public basicTP = {};//{ hour: 13, minute: 30 };


  validatore(field,event:any): void{
    this.valido=true;
    this.errMsg="";
    if(field.validationType!=undefined){
      if(this.form.controls[field.name].errors){
        this.valido=false;
        var err = validationType.find(v => v.tipo == Object.keys(this.form.controls[field.name].errors));
        this.errMsg = this.field.label + ' ' + err["msgErrore"].toString();
      }
    }
  }

  constructor(private formsService:FormService, private elem: ElementRef) { }

  ngOnInit() {
    this.valido=true;
    this.errMsg="";
    this.fType = fieldType.find(v => v.key == this.field.type).controlType;

    if(this.fType=="multiselect"){
      this.selectMulti = of(this.field.options).pipe(delay(50));
      this.selectMultiSelected = this.field.options;
      this.field.value = this.selectMultiSelected;
    }
    
    if(!this.form){
      this.validationTypes = (this.field.validationType!=undefined) ? this.formsService.setValidation(this.field.validationType) : [];
      this.campo[this.field.name] = new FormControl(this.field.value,this.validationTypes);
      this.form = new FormGroup(this.campo); 
    }
  }

}