import { environment } from "environments/environment";
import { restApiKey } from "../services/rest.service";

export class FieldModel {
    name?:string;
    value?: any;
    type? : any;
    label?: string;
    options?: DropdownOption[];
    required?: boolean;
    readonly?: boolean;
    uppercase?:boolean;
    querysql?:any;
    validationType? :any[];
    colSpace?: any;
    icon? : any;
    size? : any;
    classiCss?:any[];
    defaultValue?:string;
    functions?:FunctionsValue;
    
    constructor(field: {
    name?: any;
    value?: any;
    options?: DropdownOption[];
    label?: string;
    required?: boolean;
    readonly?: boolean;
    uppercase?:boolean;
    querysql?:any;
    validationType? : any[];
    colSpace?: any;
    type? : any;
    icon? : any;
    size? : any;
    classiCss?:any[];
    defaultValue?:string;
    functions?:FunctionsValue;

    }){
        if(field){
            this.name = field.name;
            this.value = field.value;
            this.options = field.options;
            this.label = field.label;
            this.required = field.required;
            this.readonly = field.readonly;
            this.uppercase = field.uppercase;
            this.querysql=field.querysql;
            this.validationType = field.validationType;
            this.colSpace = field.colSpace;
            this.type = field.type;
            this.icon = field.icon;
            this.size = field.size;
            this.classiCss = field.classiCss;
            this.defaultValue = (field.defaultValue === undefined ? '' : field.defaultValue);
            this.functions = field.functions;
        }
    }
}

class DropdownOption {
    value: string;
    label: any
}

class FunctionsValue {
    event: any;
    function: any;
    params:any;
}

export const validationType :any = 
[
  {
    "tipo":"required",     
    "msgErrore": " è obbligatorio",           
  }, 
  {
    "tipo":"email",
    "msgErrore": " non è una mail corretta",           
  }, 
  {
    "tipo":"maxlength",
    "msgErrore": " supera la lunghezza consentita",           
  }, 
  {
    "tipo":"minlength",
    "msgErrore": " non rispetta la lunghezza prevista",           
  }

];

export const fieldType : any = [
  /*
  0, indefinito (non viene visualizzato)
  1, campo di testo
  2, campo numerico
  3, campo data
  4, campo range di date
  5, campo combo normale (elenco in "options" oppure SQL in "querysql")
  6, campo combo LJB con valore numerico (SQL in "querysql" dove verrà effettuata una sostituzione di stringa con i tag: @@searchtext@@ contenente i caratteri digitati sulla combo dall'utente e @@and_code_equal@@ nel caso si voglia limitare il numero di records restituiti quando viene specificato un valore iniziale)
  7, campo combo con valore numerico (elenco in "options" oppure SQL in "querysql")
  8, campo combo LJB con valore stringa (SQL in "querysql" dove verrà effettuata una sostituzione di stringa con i tag: @@searchtext@@ contenente i caratteri digitati sulla combo dall'utente e @@and_code_equal@@ nel caso si voglia limitare il numero di records restituiti quando viene specificato un valore iniziale)
  9, campo combo con valore stringa (elenco in "options" oppure SQL in "querysql")
  12, campo TAG con valore numerico (elenco in "options" oppure SQL in "querysql")
  13, campo TAG con valore stringa (elenco in "options" oppure SQL in "querysql")
  14, campo mese-anno
  15, campo range mese-anno
  1000, titolo sezione
  */
  {"key":"0","controlType":"hidden"},
  {"key":"1","controlType":"textbox"},
  {"key":"2","controlType":"number"},
  {"key":"3","controlType":"datetime"},
  {"key":"4","controlType":"datetimerange"},
  {"key":"5","controlType":"dropdown"},
  {"key":"6","controlType":"dropdown"},
  {"key":"7","controlType":"dropdown"},
  {"key":"8","controlType":"dropdown"},
  {"key":"9","controlType":"dropdown"},
  {"key":"12","controlType":"multiselect"},
  {"key":"13","controlType":"multiselect"},
  {"key":"14","controlType":"datetimemy"},
  {"key":"15","controlType":"datetimemyrange"},
  /*Aggiunti*/
  {"key":"10","controlType":"radio"},
  {"key":"17","controlType":"textarea"},
  {"key":"1000","controlType":"title"},
  
];


export const functionType : any = [
  {"key":"0","tipo":"onInitialize"},
  {"key":"1","tipo":"button"},
  {"key":"2","tipo":"beforeSubmit"},  
];
