<div>
    <span *ngIf="(col.formatType === 0) || (col.formatType === 3)" class="badge badge-pill badge-light-primary mr-1">
        <i data-feather="type"></i>&nbsp;testo
      </span>
      <span *ngIf="col.formatType === 1" class="badge badge-pill badge-light-success mr-1">
        <i data-feather="calendar"></i>&nbsp;dd/mm/yyyy
      </span>
      <span *ngIf="(col.formatType === 2) || (col.formatType === 18)" class="badge badge-pill badge-light-info mr-1">
        <i data-feather="hash"></i>&nbsp;999.99 €
      </span>
      <span *ngIf="col.formatType === 4" class="badge badge-pill badge-light-primary mr-1">
        <i data-feather="type"></i>&nbsp;10 ch
      </span>
      <span *ngIf="col.formatType === 5" class="badge badge-pill badge-light-primary mr-1">
        <i data-feather="type"></i>&nbsp;20 ch
      </span>
      <span *ngIf="col.formatType === 6" class="badge badge-pill badge-light-success mr-1">
        <i data-feather="calendar"></i>&nbsp;dd mmm
      </span>
      <span *ngIf="col.formatType === 7" class="badge badge-pill badge-light-success mr-1">
        <i data-feather="calendar"></i>&nbsp;dd/mm/yy
      </span>
      <span *ngIf="col.formatType === 8" class="badge badge-pill badge-light-success mr-1">
        <i data-feather="calendar"></i>&nbsp;dd/mm/yyyy
      </span>
      <span *ngIf="col.formatType === 9" class="badge badge-pill badge-light-success mr-1">
        <i data-feather="calendar"></i>&nbsp;dd-MMM-yy
      </span>
      <span *ngIf="col.formatType === 10" class="badge badge-pill badge-light-success mr-1">
        <i data-feather="calendar"></i>&nbsp;dd mmm yyyy
      </span>
      <span *ngIf="col.formatType === 11" class="badge badge-pill badge-light-success mr-1">
        <i data-feather="calendar"></i>&nbsp;dd-mm-yyyy hh:mm
      </span>
      <span *ngIf="col.formatType === 12" class="badge badge-pill badge-light-success mr-1">
        <i data-feather="clock"></i>&nbsp;hh:mm
      </span>
      <span *ngIf="(col.formatType === 13) || (col.formatType === 19)" class="badge badge-pill badge-light-success mr-1">
        <i data-feather="calendar"></i>&nbsp;mmm yyyy
      </span>
      <span *ngIf="col.formatType === 14" class="badge badge-pill badge-light-info mr-1">
        <i data-feather="hash"></i>&nbsp;999
      </span>
      <span *ngIf="col.formatType === 15" class="badge badge-pill badge-light-info mr-1">
        <i data-feather="hash"></i>&nbsp;999.99
      </span>
      <span *ngIf="col.formatType === 16" class="badge badge-pill badge-light-info mr-1">
        <i data-feather="hash"></i>&nbsp;999.99999
      </span>
      <span *ngIf="col.formatType === 17" class="badge badge-pill badge-light-warning mr-1">
        <i data-feather="percent"></i>&nbsp;99.9
      </span>
      <span *ngIf="col.formatType === 100" class="badge badge-pill badge-light-warning mr-1">
        <i data-feather="star"></i>&nbsp;
      </span>
</div>
