import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { NgForm, FormGroup, Validators, FormControl, FormBuilder, ValidationErrors, FormArray, ValidatorFn } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { User } from 'app/auth/models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { Location } from '@angular/common';
import { RestService } from '../../services/rest.service';
import { GlobalsService } from '../../services/globals.service';

import { FieldType, FieldNone, FieldTitle, FieldText, FieldObjectComponent } from '../field-object/field-object.component';

@Component({
  selector: 'app-form-object',
  templateUrl: './form-object.component.html',
  styleUrls: ['./form-object.component.scss']
})
export class FormObjectComponent implements OnInit {

  @Input() fields = [];
  @Input() tablename = '';
  @Input() keyname = '';
  @Input() formname = '';
  @Input() savemethod = 'putrecord';
  @Input() saveLabel = 'Salva';
  @Input() backLabel = 'Indietro';
  @Output() onFieldButtonClick = new EventEmitter<any>();
  @Output() onForeingKey = new EventEmitter<any>();
  @Output() onSave = new EventEmitter<any>();
  @Output() onPreSave = new EventEmitter<any>();
  @Output() onPostRead = new EventEmitter();
  @Output() onPreCreate = new EventEmitter<FormObjectComponent>(); // quando si entra con pk = 0

  @ViewChild('modalAlert') modalAlert: ElementRef;

  //public fields = [];
  public myForm: FormGroup;
  private builderFields = {};
  private fieldValues = {};
  public pk = 0;
  currentUser: User;
  hasSaved: boolean = false;
  alertTitle: string = '';
  alertMessage: string = '';
  alertClass: string = '';
  spinner: boolean = false;
  tableName: string = '';
  tablePrimarykeyName: string = 'pk';

  constructor(
    private route: ActivatedRoute,
    private rest: RestService,
    private modalService: NgbModal,
    private location: Location,
    public formBuilder: FormBuilder,
    private globals: GlobalsService,
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }

  preFillBuilderFields(): void {
    this.builderFields = {};
    this.fieldValues = {};
    for (const [name, field] of Object.entries(this.fields)) {
      field.hasSaved = this.hasSaved;
      switch (field.type) {
        case FieldType.Text:
          this.fieldValues[field.name] = (this.pk === 0 ? field.defaultvalue : field.value);
          var fieldValidations = [];
          if (field.validations !== undefined) {
            field.validations.forEach(validation => {
              switch (validation.type) {
                case 'required':
                  fieldValidations.push(Validators.required);
                  break;
                case 'minlength':
                  fieldValidations.push(Validators.minLength(validation.param));
                  break;
                case 'maxlength':
                  fieldValidations.push(Validators.maxLength(validation.param));
                  break;
                case 'email':
                  fieldValidations.push(Validators.email);
                  break;  
                case 'pattern':
                  fieldValidations.push(Validators.pattern(validation.param));
                  break;    
              }
            });
          }
          this.builderFields[field.name] = ['', fieldValidations];
          break;
        case FieldType.Date:
          this.fieldValues[field.name] = (this.pk === 0 ? field.defaultvalue : field.value);
          var fieldValidations = [];
          if (field.validations !== undefined) {
            field.validations.forEach(validation => {
              switch (validation.type) {
                case 'required':
                  fieldValidations.push(Validators.required);
                  break;
              }
            });
          }
          this.builderFields[field.name] = ['', fieldValidations];
          break;
        case FieldType.ComboText:
          this.fieldValues[field.name] = (this.pk === 0 ? field.defaultvalue : field.value);
          var fieldValidations = [];
          if (field.validations !== undefined) {
            field.validations.forEach(validation => {
              switch (validation.type) {
                case 'required':
                  fieldValidations.push(Validators.required);
                  break;
                case 'min':
                  fieldValidations.push(Validators.min(parseInt(validation.param)));
                  break;
                case 'max':
                  fieldValidations.push(Validators.max(parseInt(validation.param)));
                  break;
              }
            });
          }
          this.builderFields[field.name] = ['', fieldValidations];
          break;
        case FieldType.CheckBox:
          this.fieldValues[field.name] = (this.pk === 0 ? field.defaultvalue : field.value);
          this.builderFields[field.name] = ['', fieldValidations];
          break;
        case FieldType.None:
          this.fieldValues[field.name] = (this.pk === 0 ? field.defaultvalue : field.value);
          this.builderFields[field.name] = ['', fieldValidations];
          break;
        

      }
    }
  }

  ngOnInit(): void {

    // read parameters from routes
    this.route.data.pipe(take(1)).subscribe((data) => {

      if (this.tablename !== '') {
        this.tableName = this.tablename;
        this.tablePrimarykeyName = this.keyname;
      } else {
        this.tableName = data.tablename;
        if (data.keyname !== undefined) {
          this.tablePrimarykeyName = data.keyname;
        }
      }
      if (this.tableName === undefined) {
        this.tableName = '';
      }
      //console.log('tableName', this.tableName);
      //console.log('tablePrimarykeyName', this.tablePrimarykeyName);


      const routeParams = this.route.snapshot.paramMap;
      const pk_parm = routeParams.get('pk');
      if (pk_parm === null) {
        this.pk = 0;
      } else {
        if (this.globals.forcepk[this.formname] !== undefined) {
          if (this.globals.forcepk[this.formname] > 0) {
            this.pk = this.globals.forcepk[this.formname];
            this.globals.forcepk[this.formname] = 0;
          }
        } else {
          this.pk = Number(routeParams.get('pk'));
        }
        // read values from restapi (async)
        if ((this.pk > 0) && (this.tableName !== '')) {
          setTimeout(() => {
            this.readRecord();
          }, 50);
        }
      }
  
      this.preFillBuilderFields();
      this.myForm = this.formBuilder.group(this.builderFields);
      this.myForm.setValue(this.fieldValues);

      // refresh dei valori di default delle date se nuovo record
      if (this.pk == 0) {
        for (var i = 0; i < this.fields.length; i++) {
          if (this.fields[i].type === FieldType.Date) {
            if (this.fields[i].dateOptions.defaultDate !== '') {
              this.fields[i].value = this.fields[i].dateOptions.defaultDate + ' 00:00:00';
              var campo = {};
              campo[this.fields[i].name] = this.fields[i].value;
              this.myForm.patchValue(campo);      
            }
          }
        }
        if (this.onPreCreate !== null) {
          if (this.onPreCreate !== undefined) {
            this.onPreCreate.emit(this);
          }
        }
      }

    });

  }

  showAlert(alertType, title, message) {
    this.alertClass = alertType;
    this.alertTitle = title;
    this.alertMessage = message;
    this.modalService.dismissAll('Accept click');
    this.modalService.open(this.modalAlert, {
      backdrop: false,
      centered: true
    });  
  }

  readRecord(): void {
    //console.log('readRecord con pk', this.pk);
    this.rest.callGetRecord(this.currentUser.token, this.tableName, this.tablePrimarykeyName, '' + this.pk)
    .subscribe(
      response => {
        // aggiorna il token
        this.currentUser.token = response.token;
        localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
        if (response.success) {
          const record = response.record[0];
          for (const [name, value] of Object.entries(record)) {

            // propaga la foreing key
            if (name === this.tablePrimarykeyName) {
              if (this.onForeingKey !== undefined) {
                if (this.onForeingKey !== null) {
                  this.onForeingKey.emit({
                    keyname: name,
                    keyvalue: value,
                  });
                }  
              }
            }

            for (var i = 0; i < this.fields.length; i++) {
              if (this.fields[i].name === name) {
                //console.log('leggo campo=' + name + ', valore=', value);
                if (this.fields[i].type === FieldType.Date) {
                  this.fields[i].value = value;
                } else if (this.fields[i].type === FieldType.ComboText) {
                  if (this.fields[i].multiple) {
                    const stringvalue:string = String(value);
                    this.fields[i].value = stringvalue.split(',');
                  } else {
                    this.fields[i].value = value;
                  }
                } else if (this.fields[i].numberMask === 2) {
                  let svalue = String(value).replace('.', ',');
                  this.fields[i].value = svalue;
                } else {
                  this.fields[i].value = value;
                }
              }
            }
          }
          this.preFillBuilderFields();
          this.myForm.setValue(this.fieldValues);

          if (this.onPostRead !== undefined) {
            if (this.onPostRead !== null) {
              this.onPostRead.emit();
            }
          }

        } else {
          this.showAlert('danger', 'ERRORE', response.error);
        }
      },
      error => {
        this.showAlert('danger', 'ERRORE', error);
      }
    );
  }

  encodeDate(datain:string, maxlength:number = 10): string { // yyyy-mm-dd 00:00:00 10/19
    var d:string = datain;
    if (d.length > 0) {
      if (d.length > maxlength) {
        d = d.substring(0, maxlength);
      }
    } else {
      d = '@@NULLDATE@@';
    }
    return d;
  }

  saveRecord(values, jump: boolean = true) {
    var record = {};
    for (const [name, value] of Object.entries(values)) {
      for (var i = 0; i < this.fields.length; i++) {
        if (this.fields[i].name === name) {
          if (this.fields[i].type === FieldType.Date) {
            if (this.fields[i].value !== undefined) {
              record[name] = this.encodeDate(this.fields[i].value);
            } else {
              record[name] = this.encodeDate(String(value));
            }
          } else if (this.fields[i].type === FieldType.DateTime) {
            if (this.fields[i].value !== undefined) {
              record[name] = this.encodeDate(this.fields[i].value, 19);
            } else {
              record[name] = this.encodeDate(String(value), 19);
            }
          } else if (this.fields[i].type === FieldType.CheckBox) {
            if (this.fields[i].value !== undefined) {
              record[name] = this.fields[i].value;
            }
          } else if (this.fields[i].type === FieldType.ComboText) {
            if (this.fields[i].multiple) {
              let multivalue = '';
              for (const [selname, selvalue] of Object.entries(value)) {
                multivalue += (multivalue === '' ? '' : ',') + selvalue;
              }
              record[name] = multivalue;
            } else {
              record[name] = value;
            }
          } else if (this.fields[i].numberMask === 2) {
            let svalue = String(value).replace(',', '.');
            record[name] = svalue;
          } else {
            record[name] = value;
          }
          //console.log('salvo campo=' + name + ', valore=', record[name]);
        }
      }
    }

    let hasPreSave:boolean = false;
    if (this.onPreSave !== undefined) {
      if (this.onPreSave !== null) {
        hasPreSave = true;
      }
    }
    if (hasPreSave) {
      this.onPreSave.emit({ record, jump });
    } else {
      this.saveRecordImmediate(record, jump);
    }
  }

  saveRecordImmediate(record, jump: boolean = true) {
    var records = [];
    records.push(record);
    this.rest.callPutRecordByMethod(this.savemethod, this.currentUser.token, this.tableName, this.tablePrimarykeyName, '' + this.pk, records)
    .subscribe(
      response => {
        // aggiorna il token
        this.currentUser.token = response.token;
        localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
        if (response.success) {
          this.goBack(response, jump);
        } else {
          this.showAlert('danger', 'ERRORE', response.error);
        }
      },
      error => {
        this.showAlert('danger', 'ERRORE', error);
      }
    );
  }

  goBack(response = {}, jump = true){
    if (jump) {
      this.location.back(); 
    } else {
      if (this.onSave !== undefined) {
        if (this.onSave !== null) {
          this.onSave.emit(response);
        }
      }
    }
  }

  submit(jump: boolean = true): void {
    this.hasSaved = true;
    for (const [name, field] of Object.entries(this.fields)) {
      field.hasSaved = this.hasSaved;
    }
    if (!this.myForm.valid) {
      this.showAlert('warning', 'ATTENZIONE!', 'Si sono verificati degli errori nella compilazione dei campi!');
    } else {
      this.saveRecord(this.myForm.value, jump);
    }
  }

  onButtonClick(item) {
    if (this.onFieldButtonClick !== undefined) {
      if (this.onFieldButtonClick !== null) {
        this.onFieldButtonClick.emit(item);
      }
    }
  }

  setFieldValue(fieldname, value) {
    var campo = {};
    campo[fieldname] = value;
    this.myForm.patchValue(campo);
  }

  getFieldValue(fieldname) {
    return this.myForm.get(fieldname).value;
  }

  getField(fieldname) {
    for (let index = 0; index < this.fields.length; index++) {
      if (this.fields[index].name === fieldname) {
        return this.fields[index];
      }
    }
  }

  onCreateObject(childInstance: FieldObjectComponent) {
    //console.log('onCreateObject', childInstance);
    //console.log('onCreateObject: field name=', childInstance.attributes.name);
    for (let index = 0; index < this.fields.length; index++) {
      if (this.fields[index].name === childInstance.attributes.name) {
        return this.fields[index].ref = childInstance;
      }
    }
  }

}
