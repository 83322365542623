import { NgModule } from '@angular/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { CoreCommonModule } from '@core/common.module';

import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule } from 'ngx-mask';

// componenti utilizzati da questi moduli
import { FormObjectComponent } from '../guiobjects/form-object/form-object.component';
import { FieldObjectComponent } from '../guiobjects/field-object/field-object.component';

import { TreeModule } from '@circlon/angular-tree-component';

@NgModule({
  declarations: [
    FormObjectComponent,
    FieldObjectComponent
  ],
  imports: [
    NgbModule,
    NgSelectModule,
    ContentHeaderModule,
    CardSnippetModule,
    Ng2FlatpickrModule,
    CoreCommonModule,
    CommonModule,
    FormsModule,
    NgxMaskModule.forRoot(),
    TreeModule
  ],
  exports: [
    FormObjectComponent,
    FieldObjectComponent
  ],
})
export class SharedComponentsModule { }
