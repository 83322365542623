import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpClientModule } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Md5 } from 'ts-md5/dist/md5';
import { Token } from '@angular/compiler/src/ml_parser/tokens';
import { Page } from '../components/age-component/age-table/table/pages';
import { User } from 'app/auth/models';
import { table } from 'console';

export const ERROR_CODE_BAD_AUTH: string = '03';

// Hello Host
// export const restApiUrl: string = 'http://localhost:8080/GenericRestGW/restapi';
export const restApiUrl = "https://app.tripapp.it/chronoapp/restapi";
export const restApiKey: string = 'XrXDcQbwyL1TFSiAtEN6qzEKKwjHDzft02zbzpZw53c';

/*

{
   "userdata": {
      "role": 2,
      "lastlogin": "2021-12-22 16:57:39",
      "lastretrycntdate": "",
      "avatar": "",
      "deployDateTime": "2021-12-22 16:29:00",
      "parentpk": 0,
      "authtype": 1,
      "lastaccess": "2021-12-22 16:58:23",
      "lastretrycnt": 0,
      "surname": "Cardarola",
      "name": "Fabio",
      "pk": 11,
      "email": "f.cardarola@agesoft.it",
      "status": 1,
      "username": "f.cardarola"
   },
   "productVersion": "V 1.0 b14",
   "menuprofile": "|homepage|dashboard|anagrafiche|sale|archiviosale|saleattive|saleinattive|giocatori|archiviogiocatori|device|attivazionedevice|deviceabilitati|devicedisabilitati|archiviodevice|pratiche|validazionepratiche|pratichebloccate|archiviopratiche|pratichemanuali|validazionepiattaforma|",
   "method": "login",
   "success": true,
   "error": "",
   "errorcode": "00",
   "productPlatform": "SVILUPPO Agesoft (DB VLT SVILUPPO)",
   "token": "wgBDM0TOQbiqrgSeo3BpZSoRdGelv2AB3rW4hXtmCIX1ASc0I3JqyAw6WjQezvZkIRK6Q7O7gNHRSwRGsL0Vmg"
}

*/

export class API_Login {
  apikey: string = restApiKey;
  method: string = 'login';
  authtype: number = 1;
  username: string;
  password: string;
  constructor(values: Object = {}) {
    Object.assign(this, values);    
  }
}
export interface UserData {
  role: number;
  lastlogin: string;
  parentpk: number;
  lastaccess: string;
  surname: string;
  name: string;
  pk: number;
  email: string;
  username: string;
}
export interface API_Login_response {
  success: boolean;
  error: string;
  token: string;
  userdata: UserData;
  menuprofile: string;
  policy: string;
  policyurl: string;
}

export class API_Reports {
  apikey: string = restApiKey;
  method: string = 'reports';
  action: string;
  code: string;
  report: any;
  token: string;
  constructor(values: Object = {}) {
    Object.assign(this, values);    
  }
}
export interface API_Reports_response {
  success: boolean;
  error: string;
  token: string;
  reports: any;
}

export class API_ExecuteReport {
  apikey: string = restApiKey;
  method: string;
  token: string;
  page: number;
  rowsPerPage: number;
  searchText: string;
  lastSearchText: string;
  apiParams: any;
  tabletype: number = 2;
  filters: any;
  prefilters: any;
  sorter: any;
  constructor(values: Object = {}) {
    Object.assign(this, values);    
  }
}
export interface ReportColumns {
  sorter: boolean;
  dataIndex: string;
  width: string;
  title: string;
  align: string;
  formatType: number;
  key: string;
  searchable: boolean;
}
export interface ReportQueryData {
  data: [];
  columns: [];
}
export interface API_ExecuteReport_response {
  success: boolean;
  error: string;
  token: string;
  querydata: ReportQueryData;
}

export class API_Products {
  apikey: string = restApiKey;
  method: string = 'products';
  action: string;
  token: string;
  constructor(values: Object = {}) {
    Object.assign(this, values);    
  }
}
export interface API_Products_response {
  success: boolean;
  error: string;
  token: string;
  products: any;
}

export interface TableColumns {
  sorter: boolean;
  dataIndex: string;
  width: string;
  title: string;
  align: string;
  formatType: number;
  key: string;
  searchable: boolean;
}
export interface TableQueryData {
  data: [];
  columns: [];
}
export interface API_Table_response {
  success: boolean;
  error: string;
  token: string;
  querydata: TableQueryData;
}

export class API_GetRecord {
  apikey: string = restApiKey;
  method: string = 'getrecord';
  token: string;
  table: string;
  keyname: string;
  keyvalue: string;
  constructor(values: Object = {}) {
    Object.assign(this, values);    
  }
}
export interface API_GetRecord_response {
  success: boolean;
  error: string;
  token: string;
  record: [any];
}

export class API_PutRecord {
  apikey: string = restApiKey;
  method: string = 'putrecord';
  token: string;
  table: string;
  keyname: string;
  keyvalue: string;
  record: any;
  constructor(values: Object = {}) {
    Object.assign(this, values);    
  }
}
export interface API_PutRecord_response {
  success: boolean;
  error: string;
  token: string;
}

@Injectable({
  providedIn: 'root'
})
export class RestService {

  constructor(private http: HttpClient) { }

  callLogin(username:string, password:string): Observable<API_Login_response> {
    const request:API_Login = new API_Login({username: username, password: Md5.hashStr(password)});
    return this.http.post<API_Login_response>(restApiUrl, request, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
      })
    });
  }

  callReports(token: string, action:string, code:string, report:any): Observable<API_Reports_response> {
    const request:API_Reports = new API_Reports({token: token, action: action, code: code, report: report});
    return this.http.post<API_Reports_response>(restApiUrl, request, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
      })
    });
  }

  callExecuteReport(token: string, method:string, page:number, rowsPerPage:number, apiParams:any, filters:any, prefilters:any, sorter:any): Observable<API_ExecuteReport_response> {
    const request:API_ExecuteReport = new API_ExecuteReport({token: token, method: method, page: page, rowsPerPage: rowsPerPage, apiParams: apiParams, filters: filters, prefilters: prefilters, sorter: sorter});
    return this.http.post<API_ExecuteReport_response>(restApiUrl, request, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
      })
    });
  }

  callProducts(token: string, action:string): Observable<API_Products_response> {
    const request:API_Products = new API_Products({token: token, action: action});
    return this.http.post<API_Products_response>(restApiUrl, request, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
      })
    });
  }

  callProductLogin(url:string, apikey:string, username:string, password:string): Observable<API_Login_response> {
    const request:API_Login = new API_Login({apikey: apikey, username: username, password: Md5.hashStr(password)});
    return this.http.post<API_Login_response>(url, request, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
      })
    });
  }

  callProductReports(url:string, apikey:string, token: string, action:string, code:string, report:any): Observable<API_Reports_response> {
    const request:API_Reports = new API_Reports({apikey: apikey, token: token, action: action, code: code, report: report});
    return this.http.post<API_Reports_response>(url, request, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
      })
    });
  }

  callProductExecuteReport(url:string, apikey:string, token: string, method:string, page:number, rowsPerPage:number, apiParams:any, filters:any, prefilters:any, sorter:any): Observable<API_ExecuteReport_response> {
    const request:API_ExecuteReport = new API_ExecuteReport({apikey: apikey, token: token, method: method, page: page, rowsPerPage: rowsPerPage, apiParams: apiParams, filters: filters, prefilters: prefilters, sorter: sorter});
    return this.http.post<API_ExecuteReport_response>(url, request, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
      })
    });
  }

  ageDataFromRestApi(params?):Observable<any>{
    return this.http.post(restApiUrl, params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
      })
    });
  }

  ageDatatableRestApi(params?):Observable<Page<API_Table_response[]>>{
    return this.http.post<Page<API_Table_response[]>>(restApiUrl, params, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
      })
    });
  }

  callGetRecord(token:string, table: string, keyname:string, keyvalue:string): Observable<API_GetRecord_response> {
    const request:API_GetRecord = new API_GetRecord({token: token, table: table, keyname: keyname, keyvalue: keyvalue});
    return this.http.post<API_GetRecord_response>(restApiUrl, request, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
      })
    });
  }

  callPutRecord(token:string, table: string, keyname:string, keyvalue:string, record:any): Observable<API_PutRecord_response> {
    const request:API_PutRecord = new API_PutRecord({token: token, table: table, keyname: keyname, keyvalue: keyvalue, record: record});
    return this.http.post<API_PutRecord_response>(restApiUrl, request, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
      })
    });
  }

  callPutRecordByMethod(method:string, token:string, table: string, keyname:string, keyvalue:string, record:any): Observable<API_PutRecord_response> {
    const request:API_PutRecord = new API_PutRecord({method: method, token: token, table: table, keyname: keyname, keyvalue: keyvalue, record: record});
    return this.http.post<API_PutRecord_response>(restApiUrl, request, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json;charset=UTF-8',
      })
    });
  }

}
