import { Component, OnInit, Input, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { FieldTitle, FieldText, FieldNone, FieldDate, FieldButton, FieldCombo, FieldCheck } from '../guiobjects/field-object/field-object.component';
import { SysUserCreateService } from './sys-user-create.service';
import { AgeTableComponent } from '../components/age-component/age-table/table/age-table.component';
import { FormObjectComponent } from '../guiobjects/form-object/form-object.component';
import { FieldObjectComponent } from '../guiobjects/field-object/field-object.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalsService } from '../services/globals.service';
import { Router } from '@angular/router'; 

@Component({
  selector: 'app-sys-user-create',
  templateUrl: './sys-user-create.component.html',
  styleUrls: ['./sys-user-create.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SysUserCreateComponent implements OnInit {

  @ViewChild('modalAlert') modalAlert: ElementRef;

  foreignkey = '0';
  alertTitle: string = '';
  alertMessage: string = '';
  alertClass: string = '';

  
  @ViewChild('frmobj39') frmobj39: FormObjectComponent;
  public fields_frmobj39 = [];


  constructor(
    private service: SysUserCreateService,
    private router: Router,
    public globals: GlobalsService,
    private modalService: NgbModal,
  ) {
    service.component = this;
    if(this.router.getCurrentNavigation().extras.state !== undefined){
      const state = this.router.getCurrentNavigation().extras.state;
      if (state.params !== undefined) {
        this.foreignkey = state.params.apiParams.fk;
      }
    } 

    
this.fields_frmobj39.push(new FieldTitle({"readonly":false,"btncolor":"","name":"none","icon":"user","defaultvalue":"","numberMask":0,"fontSize":"","label":"Creazione di un nuovo utente","validations":[],"fontColor":"","span":12}));
this.fields_frmobj39.push(new FieldText({"readonly":false,"btncolor":"","name":"name","icon":"","defaultvalue":"","numberMask":0,"label":"Nome","validations":[{"param":0,"type":"required","message":"Il campo Nome è obbligatorio !"}],"span":6}));
this.fields_frmobj39.push(new FieldText({"readonly":false,"btncolor":"","name":"surname","icon":"","defaultvalue":"","numberMask":0,"label":"Cognome","validations":[{"param":0,"type":"required","message":"Il campo Cognome è obbligatorio !"}],"span":6}));
this.fields_frmobj39.push(new FieldText({"readonly":false,"btncolor":"","name":"username","icon":"","defaultvalue":"","numberMask":0,"label":"Username","validations":[{"param":0,"type":"required","message":"Il campo Username è obbligatorio !"}],"span":6}));
this.fields_frmobj39.push(new FieldText({"readonly":false,"btncolor":"","name":"email","icon":"","defaultvalue":"","numberMask":0,"label":"Email","validations":[{"param":0,"type":"required","message":"Il campo Email è obbligatorio !"}],"span":6}));


  }

  ngOnInit(): void {

  }

  showAlert(alertType, title, message) {
    this.alertClass = alertType;
    this.alertTitle = title;
    this.alertMessage = message;
    this.modalService.dismissAll('Accept click');
    this.modalService.open(this.modalAlert, {
      backdrop: false,
      centered: true
    });  
  }

  
  setFieldValue_frmobj39(fieldname, value) {
    var campo = {};
    campo[fieldname] = value;
    this.frmobj39.myForm.patchValue(campo);
  }

  onFieldButtonClick_frmobj39(item) {

  }

  onForeingKey_frmobj39(keyspec) {
  }

  onSavefrmobj39(response) {
  }

  onPreSavefrmobj39(parms) {

    let newparms = this.service.onCreateUser(parms);
    let success = true;
    if (newparms.success !== undefined) {
      success = newparms.success;
    }
    if (success) {
      this.frmobj39.saveRecordImmediate(parms.record, parms.jump);
    }
  }

  onPostReadfrmobj39() {
  }


}

