import { Component, Input, OnInit, Output, ViewChild, ViewEncapsulation, ElementRef, EventEmitter } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CoreConfigService } from '@core/services/config.service';
import { environment } from 'environments/environment';
import { JsonpInterceptor } from '@angular/common/http';
import { Pagination } from './paged-data';
import { RestService, restApiUrl, restApiKey } from '../../../../../main/services/rest.service';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { NgbDateStruct, NgbCalendar, NgbDate, NgbDateParserFormatter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import {TableService} from '../table-action-service'
import { Router, NavigationExtras } from '@angular/router'; 

@Component({
  selector: 'app-age-table',
  templateUrl: './age-table.component.html',
  styleUrls: ['./age-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AgeTableComponent implements OnInit {

  @Input() parametri : any = {};
  @Input() parent : Function;
  @Input() getCellValue : Function;
  @Output() requestToJump = new EventEmitter<any>();

  @ViewChild('modalWarning') modalWarning: ElementRef;
  spinner: boolean = false;

  public sidebarToggleRef = false;
  public rows;
  public columns;
  public exportData;
  public selectedOption = 0;
  public ColumnMode = ColumnMode;
  public temp = [];
  public filtri : any = {};
  public filtriApplied : any = {};
  public sort : any = {};
  public lastSort : any = {};
  public addParams : any = {};
  public localConfig: any;
  
  public title:any;
  public showActionBtn : boolean = true;
  public rowActionBtn:any=[];
  public showBtnAlti:boolean=true;
  public showNewBtn : boolean = true;
  public showExportBtn : boolean = true;
  public filtrable : boolean = true;
  public paginazione : boolean = false;
  public rowHeight : number = 30;
  public footerHeight : number = 80;

  public error:any;
  
  public checkboxesDataList = []

  public pagination : Pagination = new Pagination(1, 0, 0, [10, 15, 20, 25, 30, 40, 50]);

  //DATE RANGE PICKER
  public basicDPdata: NgbDateStruct;
  public basicDateOptions: FlatpickrOptions = {
    altInput: true
  }
  public hoveredDate: NgbDate | null = null;
  public fromDate: NgbDate | null ;//= this.calendar.getNext(this.calendar.getToday(), 'd', -10);
  public toDate: NgbDate | null ;//= this.calendar.getToday();
  public today = this.calendar.getToday();

  //BACK STATE
  public extraParams:any;

  @ViewChild(DatatableComponent) table: DatatableComponent;

  private tempData = [];
  private _unsubscribeAll: Subject<any>;

  alertTitle: string = '';
  alertMessage: string = '';
  alertClass: string = '';

  constructor(
    private _ageTableService: RestService,
    private _coreConfigService: CoreConfigService,
    private calendar: NgbCalendar, 
    private _tableService : TableService,
    public formatter: NgbDateParserFormatter,
    private router: Router,
    private modalService: NgbModal,
  ) {
    this._unsubscribeAll = new Subject();

    //BACK STATE
    if(this.router.getCurrentNavigation().extras.state!=undefined){
      this.extraParams = this.router.getCurrentNavigation().extras.state.params;
    } 
  }

  checkIf(parameter, defaultValue) {
    if (parameter === undefined) {
      return defaultValue;
    } else {
      return parameter;
    }
  }

  ngOnInit(): void {

    this.title = this.checkIf(this.parametri["title"], 'Non definito !');
    this.showBtnAlti = this.checkIf(this.parametri["showBtnAlti"], false);
    this.rowActionBtn = this.checkIf(this.parametri["rowActionBtn"], []);
    this.showNewBtn = this.checkIf(this.parametri["showNewBtn"], false);
    this.showExportBtn = this.checkIf(this.parametri["showExportBtn"], false);
    this.filtrable = this.checkIf(this.parametri["filtrable"], false);
    // ??? this.paginazione = this.parametri["paginazione"];
    this.rowHeight = this.checkIf(this.parametri["rowHeight"], 30);
    this.footerHeight = this.checkIf(this.parametri["footerHeight"], 80);

    this.showActionBtn = this.parametri["showActionBtn"];

    this.localConfig = JSON.parse(localStorage.getItem('currentUser'));
    this.ageTableData();
  }

  changeSelection(key,item,isChecked){
    var chkArray=[];
    if(this.filtri.hasOwnProperty(key)){
      chkArray=this.filtri[key];
    }
    
    const index: number = chkArray.indexOf(item.label);
    if (isChecked) {
      if (index == -1) {
          chkArray.push(item.label);
      }
    }
    else{
      if (index !== -1) {
          chkArray.splice(index, 1);
      }
    }
    this.filtri[key]=chkArray;
  }

  showRow(righe){
    this.selectedOption = righe;
    this.pagination.pageSize=righe;
  }

  getFormattedValue(parent: any, columnLabel:string, columnValue:string): string {
    let value:string = '';
    if (parent.getCellValue !== null) {
      if (parent.getCellValue !== undefined) {
        value = parent.getCellValue(parent.parent, columnLabel, columnValue);
      }
    }
    return value;
  }

  actionbutton(btn: any, record: any) {
    if (this.requestToJump !== undefined) {
      if (this.requestToJump !== null) {
        this.requestToJump.emit({button: btn, data: record});
      }  
    }
  }

  show(link: any){
    //this.router.navigate([link])
    //BACK STATE
    //console.log('show-> ', link);


    const commands: any[] = [link];
    const extras: NavigationExtras = {state:{params:this.parametri.restApi,url:this.router.url}}; 

    let gobyparent:boolean = false;
    if (this.requestToJump !== undefined) {
      if (this.requestToJump !== null) {
        gobyparent = true;
      }  
    }

    if (gobyparent) {
      this.requestToJump.emit({commands, extras});
    } else {
      this.router.navigate(commands, extras);
    }

    //this.router.navigate([link], {state:{params:this.parametri.restApi,url:this.router.url}})
  }

  btnAddNew(){
    //this.router.navigate([link])
    //BACK STATE
    //console.log('show-> ', this.parametri.routeLink);

    const commands: any[] = [this.parametri.routeLink + '0'];
    const extras: NavigationExtras = {state:{params:this.parametri.restApi,url:this.router.url}}; 

    let gobyparent:boolean = false;
    if (this.requestToJump !== undefined) {
      if (this.requestToJump !== null) {
        gobyparent = true;
      }  
    }

    if (gobyparent) {
      this.requestToJump.emit({commands, extras});
    } else {
      this.router.navigate(commands, extras);
    }
    //this.router.navigate([this.parametri.routeLink + '0'], {state:{params:this.parametri.restApi,url:this.router.url}})
  }

  

  // FUNZIONE PER SET PARAMETRI CALL REST API E RESTITUZIONE DATI
  async ageTableData(){ 
    if(this.parametri.restApi.method!=undefined){

      this.parametri.restApi.tabletype = 2;
      this.parametri.restApi.searchText = "";
      this.parametri.restApi.apikey = restApiKey;
      this.parametri.restApi.sorter = this.sort;
      this.parametri.restApi.lastSearchText = "";
      this.parametri.restApi.prefilters = {};
      this.parametri.restApi.page = (this.pagination.page -1);
      this.parametri.restApi.filters = this.filtri;
      if (this.parametri.restApi.apiParams === undefined) {
        this.parametri.restApi.apiParams = {};
      }
      this.parametri.restApi.token = this.localConfig.token;

      //BACK STATE
        if(this.extraParams!=undefined){
          this.parametri.restApi["filters"]= this.extraParams.filters;
          for (let [key, value] of Object.entries(this.extraParams.filters)) {
            this.filtriApplied[key]=true;
            this.filtri[key]=value;
          }
          
          this.parametri.restApi["page"]= this.extraParams.page;
          this.pagination.page=this.extraParams.page;
          this.parametri.restApi["sorter"]= this.extraParams.sort;
          delete this.extraParams;
        }
        
        //console.log(JSON.stringify(this.parametri.restApi).replace('"addParams":{}','').replace('"addParams":{','').replace('},"token"',',"token"').replace(',,"token"',',"token"'))
        this._ageTableService.ageDatatableRestApi(JSON.stringify(this.parametri.restApi)
        /*.replace('"addParams":{}','')
        .replace('"addParams":{','')
        .replace('},"token"',',"token"')
        .replace(',,"token"',',"token"')*/
      ).subscribe(
      response => {
        // aggiorna il token
        this.localConfig.token = response["token"];
        localStorage.setItem('currentUser', JSON.stringify(this.localConfig));

        if (response["success"]) {
          this.rows = response["querydata"].data; // dati
          this.columns = response["querydata"].columns; //nome colonne
          this.exportData = response["querydata"].uploadlink; //export elenco
          this.pagination.count = response["pagination"].total; // record totali
          this.pagination.pageSize = (this.selectedOption>0) ? this.selectedOption : response["querydata"].pageSize; // item per pagina
          this.pagination.rowHeight = this.rowHeight; 
        } else {
          this.error = response["error"];
          // ALERT !!!!
          console.log('age.table.component.ts: errore restapi = ', this.error);
          this.showAlert('warning', 'ERRORE', this.error);
          //this.showAlert('error', 'ERRORE', this.error);
          //this.router.navigate(['/pages/authentication/login-v2']);
          return false;
        }
      },
      error => {
        this.error = error
        console.log('age.table.component.ts: errore network = ', this.error);
        //this.router.navigate(['/pages/authentication/login-v2']);
        return false;
      }
    );
    }
  }

  // CANCELLAZIONE RIGA DA ELENCO CON CONFIRM
  deleteItem(rowIndex,key){
    var t = this;
    Swal.fire({
        title: 'Attenzione',
        text: "Cancellare questa riga?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#15A033',
        cancelButtonColor: '#E42728',
        confirmButtonText: 'Si procedo!',
        cancelButtonText:'Annulla',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1'
        }
      }).then(function (result) {
        if (result.value) {
          Swal.fire({
            icon: 'success',
            title: 'Cancellato!',
            text: 'Riga eliminata!',
            customClass: {
              confirmButton: 'btn btn-success'
            }
          });
          t.rows.splice(rowIndex, 1);
          t.rows = t.arrayRemove(t.rows, key);
          t.parametri.restApiDel["keyvalue"] = key;
          t._ageTableService.ageDatatableRestApi(JSON.stringify(t.parametri.restApiDel));
        }
      });
  }

  arrayRemove(array, key) {
    return array.filter(function(element) {
        return element.key != key;
    });
  }

  onPageChange(event){
    this.pagination.pageSize = this.selectedOption;
    this.pagination.page = event.page;
    this.ageTableData();
  }

  onPageSizeChange(event):void{
    this.pagination.pageSize = event.target.value;
    this.pagination.page = 1;
    this.ageTableData();
  }

  sortTable(colonna,key,field){
    var sort=(this.lastSort.length<=0 || this.lastSort.columnKey!=key) ? 'descend' : ((this.lastSort.order==='descend') ? 'ascend':'descend');
    this.lastSort={
      "columnKey": key,
      "order": sort,
    };
    this.sort = {"field": field,  "column": colonna , "columnKey" :key , "order" : this.lastSort.order};
    this.ageTableData();
  }

  filterBy(colonna,data) {
      this.filtriApplied[colonna.key]=true;
      this.pagination.page = 1;
      this.ageTableData();
  }

  removeFilters(colonna){ 
    if(colonna!="" && colonna!=undefined && colonna!=null){
      delete this.filtri[colonna.key];
      delete this.filtriApplied[colonna.key];
    }
    else{
      this.filtri={};
      this.filtriApplied={};
    }
    this.pagination.page = 1;
    this.ageTableData();
  }

  onFilterTextChange(colonna,value){
    this.filtri[colonna.key]=[value];
  }

  onSelect({ selected }) {
    console.log('Select Event', selected);
  }


  onActivate(event) {}


  onDateSelection(date: NgbDate, key, datepicker: any) {
    var from=null;
    var to=null;
    if(this.filtri.hasOwnProperty(key)){
      from =this.filtri[key][0];
      to =this.filtri[key][1];
    }
    
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      from = this.formatter.format(date);
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
      to = this.formatter.format(date);
      datepicker.close();
    } else {
      this.toDate = null;
      this.fromDate = date;
      to = null;
      from = this.formatter.format(date);
    }

    this.filtri[key]=[from,to];
  }


  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }


  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }


  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  exportTableData(url: string){
    window.open(restApiUrl + '/file.pdf?data=' + url, '_blank');
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  showAlert(alertType, title, message) {
    this.alertClass = alertType;
    this.alertTitle = title;
    this.alertMessage = message;
    this.modalService.dismissAll('Accept click');
    this.modalService.open(this.modalWarning, {
      backdrop: false,
      centered: true
    });  
  }

}