import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr'; // For auth after login toast

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';

import { coreConfig } from 'app/app-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { ReportEditorModule } from 'app/main/report-editor/report-editor.module';

import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { AuthGuard, ErrorInterceptor, fakeBackendProvider, JwtInterceptor } from 'app/auth/helpers';
import {​​​​​​​​​​​​​​​​​​​ SysUsersListModule }​​​​​​​​​​​​​​​​​​​ from './main/sys-users-list/sys-users-list.module';
import {​​​​​​​​​​​​​​​​​​​ SysUserCreateModule }​​​​​​​​​​​​​​​​​​​ from './main/sys-user-create/sys-user-create.module';
import {​​​​​​​​​​​​​​​​​​​ SysProfileListModule }​​​​​​​​​​​​​​​​​​​ from './main/sys-profile-list/sys-profile-list.module';
import {​​​​​​​​​​​​​​​​​​​ SysProfileModule }​​​​​​​​​​​​​​​​​​​ from './main/sys-profile/sys-profile.module';
import {​​​​​​​​​​​​​​​​​​​ PostsConfiguratorModule }​​​​​​​​​​​​​​​​​​​ from './main/posts-configurator/posts-configurator.module';
import {​​​​​​​​​​​​​​​​​​​ EditPostModule }​​​​​​​​​​​​​​​​​​​ from './main/edit-post/edit-post.module';
import {​​​​​​​​​​​​​​​​​​​ EditRelPostModule }​​​​​​​​​​​​​​​​​​​ from './main/edit-rel-post/edit-rel-post.module';



const appRoutes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/pages/miscellaneous/error' //Error 404 - Page not found
  },
];

@NgModule({
declarations: [
  AppComponent,
],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled', // Add options right here
      relativeLinkResolution: 'legacy'
    }),
    TranslateModule.forRoot(),

    ToastrModule.forRoot(),

    //Datatables
    NgbModule,
    Ng2FlatpickrModule,
    NgSelectModule,

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,

    // App modules
    LayoutModule,
    SampleModule,
    ReportEditorModule,
SysUsersListModule,
SysUserCreateModule,
SysProfileListModule,
SysProfileModule,
PostsConfiguratorModule,
EditPostModule,
EditRelPostModule,
  ],

  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    fakeBackendProvider
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  exports: [],
  
})

export class AppModule {}
