import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-report-field-format',
  templateUrl: './report-field-format.component.html',
  styleUrls: ['./report-field-format.component.scss']
})

export class ReportFieldFormatComponent implements OnInit {

  @Input() col: any;

  constructor() { }

  ngOnInit(): void {
  }

}
